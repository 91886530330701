import { useNavigate } from 'react-router-dom';

export const useAppNavigation = () => {
  const navigate = useNavigate();

  const navigateTo = (path: string, state: any = {}) => {
    navigate(path, { state });
  };

  const generateNavigation = (path: string, state: any = {}) => () => {
    navigate(path, { state });
  };

  return { navigateTo, generateNavigation };
};
