import React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import SyncAltRoundedIcon from '@mui/icons-material/SyncAltRounded';
import PrecisionManufacturingRoundedIcon from '@mui/icons-material/PrecisionManufacturingRounded';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import ViewModuleRoundedIcon from '@mui/icons-material/ViewModuleRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import AppsRoundedIcon from '@mui/icons-material/AppsRounded';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton } from '@mui/joy';
import Divider from '@mui/joy/Divider';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExploreIcon from '@mui/icons-material/Explore';

interface SidebarProps {
  sliderOpen: boolean;
  setSliderOpen: React.Dispatch<React.SetStateAction<boolean>>;
  appMenuOpen: boolean;
  setAppMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sidebar: React.FC<SidebarProps> = ({ sliderOpen, setSliderOpen, appMenuOpen, setAppMenuOpen }) => {
  const listItemButtonStyles = {
    borderRadius: '8px',
    '&.Mui-selected': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      borderRadius: '8px',
    },
    width: '100%',
    boxSizing: 'border-box',
  };

  const renderLogoSection = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 0.9, p: 2 }}>
      <IconButton variant="soft" color="primary" size="sm" onClick={() => setAppMenuOpen(!appMenuOpen)}>
        <AppsRoundedIcon />
      </IconButton>
      <Typography component="h5">Lego</Typography>
    </Box>
  );

  const renderMainList = () => (
    <List size="sm">
      <ListItem sx={{ mb: '2px' }}>
        <ListItemButton component={RouterLink} to="/objects" selected sx={listItemButtonStyles}>
          <ViewModuleRoundedIcon />
          <ListItemContent>
            <Typography level="title-sm">Objects</Typography>
          </ListItemContent>
        </ListItemButton>
      </ListItem>

      <ListItem sx={{ mb: '2px' }}>
        <ListItemButton sx={listItemButtonStyles}>
          <SyncAltRoundedIcon />
          <ListItemContent>
            <Typography level="title-sm">Channels</Typography>
          </ListItemContent>
        </ListItemButton>
      </ListItem>

      <ListItem sx={{ mb: '2px' }}>
        <ListItemButton component={RouterLink} to="/workflows" sx={listItemButtonStyles}>
          <PrecisionManufacturingRoundedIcon />
          <ListItemContent>
            <Typography level="title-sm">Automations</Typography>
          </ListItemContent>
        </ListItemButton>
      </ListItem>

      <ListItem sx={{ mb: '2px' }}>
        <ListItemButton component={RouterLink} to="/profiles" sx={listItemButtonStyles}>
          <SecurityRoundedIcon />
          <ListItemContent>
            <Typography level="title-sm">Profiles</Typography>
          </ListItemContent>
        </ListItemButton>
      </ListItem>

      <ListItem sx={{ mb: '2px' }}>
      <ListItemButton component={RouterLink} to="/users" sx={listItemButtonStyles}>
          <GroupRoundedIcon />
          <ListItemContent>
            <Typography level="title-sm">Users</Typography>
          </ListItemContent>
        </ListItemButton>
      </ListItem>
    </List>
  );

  const renderSecondaryList = () => (
    <Box>
      <Typography level="title-lg" sx={{ pl: 2, pt: 1, color: 'primary.main' }} color="primary" >
        <DashboardIcon sx={{ mr: 1 }} />
        Connected
      </Typography>
      <List sx={{ ml: 2 }}>
        <ListItem>
          <ListItemButton sx={{ '&:hover': { borderRadius: '8px', backgroundColor: 'rgba(0, 0, 0, 0.05)' } }}>
            <ListItemContent>              
              <Typography level="title-sm">CRM</Typography>
            </ListItemContent>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton sx={{ '&:hover': { borderRadius: '8px', backgroundColor: 'rgba(0, 0, 0, 0.05)' } }}>
            <ListItemContent>              
              <Typography level="title-sm">Support</Typography>
            </ListItemContent>
          </ListItemButton>
        </ListItem>
      </List>
      <Typography level="title-lg" sx={{ pl: 2, pt: 1, color: 'primary.main' }} color="primary" >
        <ExploreIcon sx={{ mr: 1 }} />
        Explore
      </Typography>
      <List sx={{ ml: 2 }}>
        <ListItem>
          <ListItemButton sx={{ '&:hover': { borderRadius: '8px', backgroundColor: 'rgba(0, 0, 0, 0.05)' } }}>
            <ListItemContent>              
              <Typography level="title-sm">ITSM</Typography>
            </ListItemContent>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton sx={{ '&:hover': { borderRadius: '8px', backgroundColor: 'rgba(0, 0, 0, 0.05)' } }}>
            <ListItemContent>              
              <Typography level="title-sm">Project Management</Typography>
            </ListItemContent>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <Sheet
        className="Sidebar"
        sx={{
          position: { xs: 'fixed', md: 'sticky' },
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
            md: 'none',
          },
          transition: 'transform 0.4s, width 0.4s',
          zIndex: 10000,
          height: '100vh',
          width: 'var(--Sidebar-width)',
          top: 0,          
          flexShrink: 0,
          display: 'flex',
          flexDirection: 'column',          
          borderRight: '1px solid',
          borderColor: 'divider',
        }}
      >
        <GlobalStyles
          styles={(theme) => ({
            ':root': {
              '--Sidebar-width': '220px',
              [theme.breakpoints.up('lg')]: {
                '--Sidebar-width': '240px',
              },
            },
          })}
        />
        {renderLogoSection()}
        <Divider sx={{ mt: 1, mb: 1, width: '100%' }} />
        <Box
          sx={{
            minHeight: 0,
            overflow: 'hidden auto',
            flexGrow: 1,
            display: 'flex',
            p: 2, 
            gap: 2,
            flexDirection: 'column',
            [`& .${listItemButtonClasses.root}`]: {
              gap: 0.5, // Reduced space between items
            },
          }}
        >
          {appMenuOpen ? renderSecondaryList() : renderMainList()}
        </Box>        
      </Sheet>
      {sliderOpen && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'flex-start',
            zIndex: 10001,
          }}
          onClick={() => setSliderOpen(false)}
        >
          <Box
            sx={{
              width: 'var(--Sidebar-width)',
              height: '100vh',
              backgroundColor: 'background.level1', // Use theme background level1 color
              boxShadow: '2px 0 5px rgba(0,0,0,0)',
              padding: 2,
              transition: 'transform 2s ease-in-out',
              transform: appMenuOpen ? 'translateX(0)' : 'translateX(-100%)',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {renderLogoSection()}
            <Divider orientation="horizontal" sx={{ my: 2 }} />
            {renderSecondaryList()}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Sidebar;
