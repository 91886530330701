import React, { useEffect, useState } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import { Select, Option } from '@mui/joy';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Modal from '@mui/joy/Modal';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CircularProgress from '@mui/material/CircularProgress';
import { IUser } from '../../../interfaces/User';
import { loadUser, saveUser, updateUser } from '../../../services/user/UserService';
import { loadBProfiles } from '../../../services/b-profile/BProfileService';
import { IBProfile } from '../../../interfaces/BProfile';

interface UserFormProps {
  open: boolean;
  onClose: () => void;
  editUserId: number | null;
  showSnackBar: (message: string) => void;
  handleSave: (user: IUser) => void;
}

const UserForm: React.FC<UserFormProps> = ({ open, onClose, editUserId, showSnackBar, handleSave }) => {
  const [user, setUser] = useState<IUser>({
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    profile: { id: '', label: '', internalName: '', description: '' },
  });
  const [profiles, setProfiles] = useState<IBProfile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (editUserId) {
      setLoading(true);
      loadUser(editUserId).then((data) => {
        setUser(data);
        setLoading(false);
      }).catch(() => setLoading(false));
    } else {
      setUser({
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        profile: { id: '', label: '', internalName: '', description: '' },
      });
    }
  }, [editUserId]);

  useEffect(() => {
    loadBProfiles().then((data) => {
      setProfiles(data);
    }).catch((error) => {
      console.error('Error loading profiles:', error);
    });
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleProfileChange = (e: any, newValue: string | null) => {
    const selectedProfile = profiles.find(profile => profile.label === newValue);
    setUser((prevUser) => ({
      ...prevUser,
      profile: selectedProfile ? selectedProfile : prevUser.profile,
    }));
  };

  const handleSaveUser = async () => {
    setLoading(true);
    try {
      let savedUser: IUser;
      if (editUserId) {
        savedUser = await updateUser(editUserId, user);
        showSnackBar("User Updated Successfully!!!");
      } else {
        savedUser = await saveUser(user);
        showSnackBar("User Created Successfully!!!");
      }
      handleSave(savedUser); 
      resetForm();
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      setLoading(false);
      onClose();
    }
  };


  const resetForm = () => {
    setUser({
      id: 0,
      firstName: '',
      lastName: '',
      email: '',
      profile: { id: '', label: '', internalName: '', description: '' },
    });
  };

  return (
    <Modal open={open} onClose={onClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Card sx={{ width: { xs: '100%', md: '40%' }, p: 3, borderRadius: 'md', boxShadow: 'lg' }}>
        <Typography level="h4" component="h2" mb={2}>
          {editUserId ? 'Edit User' : 'Create User'}
        </Typography>
        <Stack spacing={2}>
          <FormControl>
            <FormLabel>First Name</FormLabel>
            <Input
              size="sm"
              placeholder="First Name"
              name="firstName"
              value={user.firstName}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Last Name</FormLabel>
            <Input
              size="sm"
              placeholder="Last Name"
              name="lastName"
              value={user.lastName}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input
              size="sm"
              placeholder="Email"
              name="email"
              value={user.email}
              onChange={handleChange}
              disabled={!!editUserId}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Profile</FormLabel>
            <Select
              size="sm"
              name="profile"
              value={user.profile.label}
              onChange={handleProfileChange}
            >
              {profiles.map((profile) => (
                <Option key={profile.id} value={profile.label}>
                  {profile.label}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button size="sm" variant="outlined" color="neutral" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            size="sm"
            variant="solid"
            onClick={handleSaveUser}
            disabled={loading}
            startDecorator={loading && <CircularProgress size={16} />}
            sx={{ ml: 1 }}
          >
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default UserForm;
