import React, { useState } from 'react';
import Box from '@mui/joy/Box';
import { Route, Routes } from 'react-router-dom';
import SnackbarComponent from '../../../ui/SnackbarComponent';
import WorkflowList from './WorkflowList';
import WorkflowEditor from './WorkflowEditor';

const WorkflowContainer: React.FC = () => {
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');

  const showSnackBar = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <React.Fragment>
      <Box>
        <Routes>
          <Route path="/" element={<WorkflowList showSnackBar={showSnackBar} />} />
          <Route path="/create" element={<WorkflowEditor />} />
          <Route path="/:workflowId/edit" element={<WorkflowEditor />} />
        </Routes>
      </Box>
      <SnackbarComponent
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </React.Fragment>
  );
};

export default WorkflowContainer;
