import React, { useRef } from 'react';
import { useDrag, useDrop, DragSourceMonitor, DropTargetMonitor } from 'react-dnd';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import Typography from '@mui/joy/Typography';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { IBField } from '../../../../interfaces/BObject';
import { ColorPaletteProp } from '@mui/joy';
import RowMenu from '../../../ui/RowMenu';

const ItemType = {
  FIELD: 'field',
};

interface BFieldListRowProps {
  field: IBField;
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  handleOpenPermissionModal: (internalName: string) => void;
  handleOpenModal: (fieldInternalName: string | null) => void;
  handleDrop: () => void; // Add handleDrop prop
}

interface DragItem {
  index: number;
  type: string;
}

const BFieldListRow: React.FC<BFieldListRowProps> = ({
  field,
  index,
  moveRow,
  handleOpenPermissionModal,
  handleOpenModal,
  handleDrop, // Destructure handleDrop
}) => {
  const ref = useRef<HTMLTableRowElement>(null);

  const [, drop] = useDrop<DragItem, void>({
    accept: ItemType.FIELD,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {
        return;
      }
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop: () => {
      handleDrop(); // Call handleDrop when the drag operation ends
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType.FIELD,
    item: { index },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const getFieldTypeColor = (fieldType: string): ColorPaletteProp => {
    switch (fieldType) {
      case 'TEXT':
        return 'primary' as ColorPaletteProp;
      case 'NUMBER':
        return 'info' as ColorPaletteProp;
      case 'EMAIL':
        return 'success' as ColorPaletteProp;
      case 'DROPDOWN':
        return 'warning' as ColorPaletteProp;
      default:
        return 'neutral' as ColorPaletteProp;
    }
  };

  return (
    <tr ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <td style={{ cursor: 'move' }}>
        <DragIndicatorIcon />
      </td>
      <td><Typography level="body-xs">{field.label}</Typography></td>
      <td>
        <Chip variant="soft" size="sm" color={getFieldTypeColor(field.fieldType.name)}>
          {field.fieldType.name}
        </Chip>
      </td>
      <td>
        {field.required ? (
          <Chip variant="soft" size="sm" color="success">Yes</Chip>
        ) : (
          <Chip variant="soft" size="sm" color="neutral">No</Chip>
        )}
      </td>
      <td>
        {field.unique ? (
          <Chip variant="soft" size="sm" color="success">Yes</Chip>
        ) : (
          <Chip variant="soft" size="sm" color="neutral">No</Chip>
        )}
      </td>
      <td>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <RowMenu
            menuItems={[
              { label: 'Set Permission', onClick: () => handleOpenPermissionModal(field.internalName), color: 'default' },
              { label: 'Edit Properties', onClick: () => handleOpenModal(field.internalName), color: 'default' },
              { label: 'Delete', onClick: () => console.log('Delete clicked'), color: 'danger' },
            ]}
          />
        </Box>
      </td>
    </tr>
  );
};

export default BFieldListRow;