// WorkflowForm.tsx
import React, { useState, useEffect } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import { Select, Option } from '@mui/joy';
import Textarea from '@mui/joy/Textarea';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Modal from '@mui/joy/Modal'; 
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CircularProgress from '@mui/joy/CircularProgress';
import { IBObject } from '../../../../interfaces/BObject';
import { loadBObjects } from '../../../../services/b-object/BObjectService';
import { saveWorkflow } from '../../../../services/workflow/WorkflowService';
import { useNavigate } from 'react-router-dom';

interface WorkflowFormProps {
  open: boolean;
  onClose: () => void;
  showSnackBar: (message: string) => void;
}

const WorkflowForm: React.FC<WorkflowFormProps> = ({ open, onClose, showSnackBar }) => {
  const [bObjects, setBObjects] = useState<IBObject[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [bObject, setBObject] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    if (open) {
      const fetchBObjects = async () => {
        setLoading(true);
        try {
          const data = await loadBObjects();
          setBObjects(data);
        } catch (error) {
          console.error('Error loading BObjects:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchBObjects();
    }
  }, [open]);

  const handleSave = async () => {
    setLoading(true);
    try {
      const workflow = {
        bobjectId : bObject,
        name,
        description,
      };
      const savedWorkflow = await saveWorkflow(workflow); 
      showSnackBar('Workflow created successfully');
      navigate(`/workflows/${savedWorkflow.id}/edit`);
    } catch (error) {
      console.error('Error saving workflow:', error);
      showSnackBar('Failed to create workflow');
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const handleBObjectChange = (e: any, newValue: number | null) => {
    setBObject(newValue as number);
  };

  return (
    <Modal open={open} onClose={onClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Card sx={{ width: { xs: '100%', md: '40%' }, p: 3, borderRadius: 'md', boxShadow: 'lg' }}>
        <Typography level="h4" component="h2" mb={2}>
          Create Workflow
        </Typography>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>BObject</FormLabel>
              <Select
                size="sm"
                value={bObject}
                onChange={handleBObjectChange}
              >
                {bObjects.map((bObject) => (
                  <Option key={bObject.internalName} value={bObject.id}>
                    {bObject.label}
                  </Option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                size="sm"
                placeholder="Workflow Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                size="sm"
                placeholder="Description"
                minRows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>
          </Stack>
        )}
        <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button size="sm" variant="outlined" color="neutral" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            size="sm"
            variant="solid"
            onClick={handleSave}
            disabled={loading || !bObject || !name}
            sx={{ ml: 1 }}
          >
            {loading ? 'Saving...' : 'Create'}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default WorkflowForm;
