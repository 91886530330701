import React, { useState } from 'react';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import Divider from '@mui/joy/Divider';
import Avatar from '@mui/joy/Avatar';
import Typography from '@mui/joy/Typography';
import ColorSchemeToggle from './ColorSchemaToggle';

interface TopNavbarProps {
  toggleSlider: () => void;
  appMenuOpen: boolean;
}

const TopNavbar: React.FC<TopNavbarProps> = ({ toggleSlider, appMenuOpen }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 2,
        borderBottom: '1px solid',
        borderColor: 'divider',
        height: '80px',
        backgroundColor: 'background.paper',
        position: 'fixed',
        left: 'var(--Sidebar-width)',
        top: 0,
        width: 'calc(100% - var(--Sidebar-width))',
        zIndex: 1100,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {/* Other elements */}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <ColorSchemeToggle />
        <Divider orientation="vertical" sx={{ height: 28, mx: 1 }} />
        <IconButton onClick={handleClick}>
          <Avatar>U</Avatar>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          placement="bottom-end"
        >
          <MenuItem onClick={handleClose}>User Preferences</MenuItem>
          <MenuItem onClick={handleClose}>Logout</MenuItem>
        </Menu>
        {appMenuOpen && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              zIndex: 10000,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default TopNavbar;
