import React, { useEffect, useState } from 'react';
import { Box, Modal, Typography, Table, Sheet, Checkbox, CircularProgress, Button } from '@mui/joy';
import { fetchData } from '../../../middleware/api';
import { IBObject } from '../../../interfaces/BObject';
import { loadBObject, saveBObjectPermission } from '../../../services/b-object/BObjectService';

interface Profile {
  id: number;
  name: string;
  label: string;
}

interface PermissionsModalProps {
  open: boolean;
  onClose: () => void;
  internalName: string;
  showSnackBar: (message: string) => void;
}

const BObjectPermission: React.FC<PermissionsModalProps> = ({ open, onClose, internalName, showSnackBar }) => {
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [permissions, setPermissions] = useState<{ [key: number]: string[] }>({});

  useEffect(() => {
    if (open) {
      const fetchProfilesAndBObject = async () => {
        setLoading(true);
        try {
          const [profilesResponse, bObject] = await Promise.all([
            fetchData('/_/admin/b-profiles/_/'),
            loadBObject(internalName),
          ]);
          setProfiles(profilesResponse.content);
          initializePermissions(profilesResponse.content, bObject);
        } catch (err) {
          if (err instanceof Error) {
            setError(err.message);
          } else {
            setError('An unknown error occurred');
          }
        } finally {
          setLoading(false);
        }
      };
      fetchProfilesAndBObject();
    }
  }, [open, internalName]);

  const initializePermissions = (profiles: Profile[], bObject: IBObject) => {
    const initialPermissions: { [key: number]: string[] } = {};
    profiles.forEach((profile) => {
      if (bObject && bObject.permission && bObject.permission[profile.id]) {
        initialPermissions[profile.id] = bObject.permission[profile.id];
      } else {
        initialPermissions[profile.id] = ['HIDDEN'];
      }
    });
    setPermissions(initialPermissions);
  };

  const handlePermissionChange = (profileId: number, permission: string) => {
    setPermissions((prevPermissions) => {
      const currentPermissions = prevPermissions[profileId] || [];
      let updatedPermissions;
      if (currentPermissions.includes(permission)) {
        updatedPermissions = currentPermissions.filter((perm) => perm !== permission);
      } else {
        updatedPermissions = [...currentPermissions, permission];
      }

      if (permission === 'HIDDEN' && !updatedPermissions.includes('HIDDEN')) {
        updatedPermissions = ['HIDDEN'];
      } else {
        updatedPermissions = updatedPermissions.filter((perm) => perm !== 'HIDDEN');
      }

      if (updatedPermissions.length === 0) {
        updatedPermissions = ['HIDDEN'];
      }

      return {
        ...prevPermissions,
        [profileId]: updatedPermissions,
      };
    });
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await saveBObjectPermission(internalName, permissions);
      showSnackBar('Permissions Updated Successfully!!!');
      onClose();
    } catch (error) {
      console.error('Error saving permissions:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={onClose}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Sheet sx={{ width: 600, p: 3, borderRadius: 'md', boxShadow: 'lg' }}>
          <Typography level="h4" component="h2" mb={2}>
            Edit Permissions
          </Typography>
          {loading ? (
            <CircularProgress />
          ) : error ? (
            <Typography color="danger">{error}</Typography>
          ) : (
            <Table>
              <thead>
                <tr>
                  <th>Label</th>
                  <th>Create</th>
                  <th>Update</th>
                  <th>View</th>
                  <th>Hidden</th>
                </tr>
              </thead>
              <tbody>
                {profiles.map((profile) => (
                  <tr key={profile.id}>
                    <td>{profile.label}</td>
                    <td>
                      <Checkbox
                        checked={permissions[profile.id]?.includes('CREATE') || false}
                        onChange={() => handlePermissionChange(profile.id, 'CREATE')}
                      />
                    </td>
                    <td>
                      <Checkbox
                        checked={permissions[profile.id]?.includes('UPDATE') || false}
                        onChange={() => handlePermissionChange(profile.id, 'UPDATE')}
                      />
                    </td>
                    <td>
                      <Checkbox
                        checked={permissions[profile.id]?.includes('VIEW') || false}
                        onChange={() => handlePermissionChange(profile.id, 'VIEW')}
                      />
                    </td>
                    <td>
                      <Checkbox
                        checked={permissions[profile.id]?.includes('HIDDEN') || false}
                        onChange={() => handlePermissionChange(profile.id, 'HIDDEN')}
                        disabled={
                          permissions[profile.id]?.includes('CREATE') ||
                          permissions[profile.id]?.includes('UPDATE') ||
                          permissions[profile.id]?.includes('VIEW')
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="plain" color="neutral" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="solid" sx={{ ml: 1 }} onClick={handleSave}>
              Save
            </Button>
          </Box>
        </Sheet>
      </Modal>
    </React.Fragment>
  );
};

export default BObjectPermission;
