import React from 'react';
import { Select, Option, List, ListItemDecorator, Typography, Chip, ListDivider, ListItem } from '@mui/joy';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ParagraphIcon from '@mui/icons-material/Notes';
import NumberIcon from '@mui/icons-material/Exposure';
import EmailIcon from '@mui/icons-material/Email';
import DropdownIcon from '@mui/icons-material/ArrowDropDownCircle';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LinkIcon from '@mui/icons-material/Link';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PhoneIcon from '@mui/icons-material/Phone';
import CheckboxIcon from '@mui/icons-material/CheckBox';
import Box from '@mui/joy/Box'; 

interface FieldTypeSelectProps {
  value: string;
  onChange: (e: any, newValue: any) => void;
  disabled: boolean;
}

const BFieldTypeDropDown: React.FC<FieldTypeSelectProps> = ({ value, onChange, disabled }) => {
  const fieldGroups = {
    "Text Fields": [
      { name: 'TEXT', description: 'Single line text', icon: <TextFieldsIcon /> },
      { name: 'PARAGRAPH', description: 'Multiple lines of text', icon: <ParagraphIcon /> },
    ],
    "Number Fields": [
      { name: 'NUMBER', description: 'Number', icon: <NumberIcon /> },
      { name: 'LONG_NUMBER', description: 'Long Number', icon: <NumberIcon /> },
      { name: 'DECIMAL', description: 'Decimal', icon: <NumberIcon /> },
    ],
    "Other Fields": [
      { name: 'EMAIL', description: 'Email', icon: <EmailIcon /> },
      { name: 'DROPDOWN', description: 'Dropdown', icon: <DropdownIcon /> },
      { name: 'DATE', description: 'Date', icon: <DateRangeIcon /> },
      { name: 'DATE_TIME', description: 'Date and Time', icon: <DateRangeIcon /> },
      { name: 'URL', description: 'Url', icon: <LinkIcon /> },
      { name: 'CURRENCY', description: 'Currency', icon: <AttachMoneyIcon /> },
      { name: 'PHONE_NUMBER', description: 'Phone Number', icon: <PhoneIcon /> },
      { name: 'CHECKBOX', description: 'Checkbox', icon: <CheckboxIcon /> },
    ]
  };

  return (
    <Select
      size="sm"
      name="fieldType"
      value={value}
      onChange={onChange}
      disabled={disabled}
      slotProps={{
        listbox: {
          component: 'div',
          sx: {
            maxHeight: 240,
            overflow: 'auto',
            '--List-padding': '0px',
            '--ListItem-radius': '0px',
          },
        },
      }}
    >
      {Object.entries(fieldGroups).map(([groupName, fields], groupIndex) => (
        <React.Fragment key={groupName}>
          {groupIndex !== 0 && <ListDivider role="none" />}
          <List
            aria-labelledby={`select-group-${groupName}`}
            sx={{ '--ListItemDecorator-size': '28px' }}
          >
            <ListItem id={`select-group-${groupName}`} sticky>
              <Typography level="body-xs" textTransform="uppercase">
                {groupName} ({fields.length})
              </Typography>
            </ListItem>
            {fields.map((fieldType) => (
              <Option
                key={fieldType.name}
                value={fieldType.name}
                label={fieldType.name}
              >
                <ListItemDecorator>
                  {fieldType.icon}
                </ListItemDecorator>
                <Box component="span" sx={{ display: 'block' }}>
                  <Typography component="span" level="title-sm">
                    {fieldType.name}
                  </Typography>
                  <Typography level="body-xs">{fieldType.description}</Typography>
                </Box>
              </Option>
            ))}
          </List>
        </React.Fragment>
      ))}
    </Select>
  );
};

export default BFieldTypeDropDown;
