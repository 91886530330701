import { NodeTypes } from '@xyflow/react';

import PlaceholderNode from './PlaceholderNode';
import WorkflowNode from './WorkflowNode';
import TriggerNode from './TriggerNode';
import FilterNode from './FilterNode';

// two different node types are needed for our example: workflow and placeholder nodes
const nodeTypes: NodeTypes = {
  placeholder: PlaceholderNode,
  workflow: WorkflowNode,
  trigger: TriggerNode,
  filter: FilterNode
};

export default nodeTypes;
