import { useState, useEffect } from 'react';
import { IBObject } from '../../interfaces/BObject';
import { loadBObjects } from '../../services/b-object/BObjectService';

export const useFetchBObjects = () => {
  const [bObjects, setBObjects] = useState<IBObject[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBObjects = async () => {
      setLoading(true);
      try {
        const objects = await loadBObjects();        
        setBObjects(objects);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError('An unknown error occurred');
        }
      } finally {
        setLoading(false);
      }
    };
    fetchBObjects();
  }, []);

  return { bObjects, loading, error };
};
