import { useState, useEffect } from 'react';
import { IBProfile } from '../../interfaces/BProfile';
import { loadBProfiles } from '../../services/b-profile/BProfileService';

export const useFetchBProfiles = () => {
  const [bProfiles, setBProfiles] = useState<IBProfile[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBProfiles = async () => {
      setLoading(true);
      try {
        const profiles = await loadBProfiles();
        setBProfiles(profiles);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError('An unknown error occurred');
        }
      } finally {
        setLoading(false);
      }
    };
    fetchBProfiles();
  }, []);

  return { bProfiles, loading, error };
};
