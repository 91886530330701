import React, { useState } from 'react';
import update from 'immutability-helper';
import Box from '@mui/joy/Box';
import Link from '@mui/joy/Link';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { Button, CircularProgress, Breadcrumbs } from '@mui/joy';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ViewModuleRoundedIcon from '@mui/icons-material/ViewModuleRounded';
import { useFetchBFields } from '../../../../hooks/b-object/useFetchBFields';
import BFieldForm from './BFieldForm';
import BFieldPermission from './BFieldPermission';
import { IBField, IBFieldChoice } from '../../../../interfaces/BObject';
import { saveBField, updateBField, updateBFieldOrder } from '../../../../services/b-object/BFieldService';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import BFieldListRow from './BFieldListRow';

interface BFieldListProps {
  objectName: string;
  showSnackBar: (message: string) => void;
}

const BFieldList: React.FC<BFieldListProps> = ({ objectName, showSnackBar }) => {
  const { bFields, setBFields, loading } = useFetchBFields(objectName);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [permissionOpen, setPermissionOpen] = useState<boolean>(false);
  const [editFieldInternalName, setEditFieldInternalName] = useState<string | null>(null);
  const [selectedInternalName, setSelectedInternalName] = useState<string | null>(null);

  const handleButtonClick = () => {
    setEditFieldInternalName(null);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setEditFieldInternalName(null);
  };

  const handleOpenModal = (fieldInternalName: string | null) => {
    setEditFieldInternalName(fieldInternalName);
    setModalOpen(true);
  };

  const handleOpenPermissionModal = (internalName: string) => {
    setSelectedInternalName(internalName);
    setPermissionOpen(true);
  };

  const handleClosePermissionModal = () => {
    setPermissionOpen(false);
    setSelectedInternalName(null);
  };

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const dragField = bFields[dragIndex];
    const updatedFields = update(bFields, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragField],
      ],
    }).map((field, index) => ({ ...field, displayOrder: index }));
  
    setBFields(updatedFields);
  };
  
  const handleDrop = () => {
    const fieldOrders = bFields.map(({ internalName, displayOrder }) => ({
      internalName,
      displayOrder: displayOrder ?? 0,
    }));
    
    updateBFieldOrder(objectName, fieldOrders)
      .then(() => showSnackBar('Display order updated successfully!'))
      .catch((error) => console.error('Error updating display order:', error));
  };

  const handleSave = async (bField: IBField, choices: IBFieldChoice[]) => {
    const fieldData: IBField = {
      ...bField,
      choices: bField.fieldType.name === 'DROPDOWN' ? choices.map((choice) => ({ id: choice.id, value: choice.value })) : [],
    };
    if (editFieldInternalName) {
      await updateBField(objectName, fieldData);
      setBFields((prevFields) =>
        prevFields.map((field) =>
          field.id === fieldData.id ? { ...field, ...fieldData } : field
        )
      );
      showSnackBar('Field Updated Successfully!!!');
    } else {
      const response = await saveBField(objectName, fieldData);
      const newField = response[0];
      setBFields((prevFields) => [...prevFields, newField]);
      showSnackBar('Field Created Successfully!!!');
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ px: { xs: 2, md: 6 }, mt: 2 }}>
          <Breadcrumbs
            size="sm"
            aria-label="breadcrumbs"
            separator={<ChevronRightRoundedIcon />}
            sx={{ pl: 0 }}
          >
            <Link underline="none" color="neutral" aria-label="Home">
              <ViewModuleRoundedIcon />
            </Link>
            <Link underline="hover" color="neutral" fontSize={12} fontWeight={500}>
              Objects
            </Link>
            <Typography color="primary" fontWeight={500} fontSize={12}>
              {objectName}
            </Typography>
            <Typography color="primary" fontWeight={500} fontSize={12}>
              Fields
            </Typography>
          </Breadcrumbs>
          <Box
            sx={{
              display: 'flex',
              mb: 1,
              gap: 1,
              mt: 4,
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Typography level="h3" component="h1">
              Fields
            </Typography>
            <Button color="primary" size="sm" onClick={handleButtonClick}>
              New Field
            </Button>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Sheet
              className="FieldTableContainer"
              variant="outlined"
              sx={{
                display: { xs: 'none', sm: 'initial' },
                width: '80%',
                borderRadius: 'sm',
                flexShrink: 1,
                overflow: 'auto',
                minHeight: 0,
              }}
            >
              <Table
                aria-labelledby="tableTitle"
                stickyHeader
                hoverRow
                sx={{
                  '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                  '--Table-headerUnderlineThickness': '1px',
                  '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                  '--TableCell-paddingY': '4px',
                  '--TableCell-paddingX': '8px',
                }}
              >
                <thead>
                  <tr>
                    <th style={{ width: 40, padding: '12px 6px' }}></th>
                    <th style={{ width: 140, padding: '12px 6px' }}>Label</th>
                    <th style={{ width: 140, padding: '12px 6px' }}>Field Type</th>
                    <th style={{ width: 140, padding: '12px 6px' }}>Required</th>
                    <th style={{ width: 140, padding: '12px 6px' }}>Unique</th>
                    <th style={{ width: 140, padding: '12px 6px' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {bFields.map((bField, index) => (
                    <BFieldListRow
                      key={bField.id}
                      index={index}
                      field={bField}
                      moveRow={moveRow}
                      handleOpenPermissionModal={handleOpenPermissionModal}
                      handleOpenModal={handleOpenModal}
                      handleDrop={handleDrop} // Pass handleDrop to the row component
                    />
                  ))}
                </tbody>
              </Table>
            </Sheet>
          </Box>
        </Box>
      )}
      {modalOpen && (
        <BFieldForm
          open={modalOpen}
          onClose={handleCloseModal}
          objectName={objectName}
          fieldInternalName={editFieldInternalName}
          showSnackBar={showSnackBar}
          handleSave={handleSave}
        />
      )}
      {selectedInternalName && (
        <BFieldPermission
          open={permissionOpen}
          onClose={handleClosePermissionModal}
          objectName={objectName}
          fieldInternalName={selectedInternalName}
          showSnackBar={showSnackBar}
        />
      )}
    </DndProvider>
  );
};

export default BFieldList;