import React, { useState } from 'react';
import update from 'immutability-helper';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import Link from '@mui/joy/Link';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { Button, CircularProgress, Breadcrumbs } from '@mui/joy';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ViewModuleRoundedIcon from '@mui/icons-material/ViewModuleRounded';
import { useFetchUsers } from '../../../hooks/user/useFetchUser';
import RowMenu from '../../ui/RowMenu';
import UserForm from './UserForm';
import { IUser } from '../../../interfaces/User';


interface UserListProps {
  showSnackBar: (message: string) => void;
}

const UserList: React.FC<UserListProps> = ({ showSnackBar }) => {
  const { users, setUsers, loading } = useFetchUsers();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<number | null>(null);

  const handleButtonClick = () => {
    setSelectedUser(null);
    setIsModalOpen(true);
  };

  const handleOpenModal = (userId: number | null) => {
    setSelectedUser(userId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSave = async (user: IUser) => {
    if (selectedUser) {
      setUsers((prevUsers) =>
        prevUsers.map((u) => (u.id === user.id ? user : u))
      );      
    } else {      
      setUsers((prevUsers) => [...prevUsers, user]);      
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ px: { xs: 2, md: 6 }, mt: 2 }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" aria-label="Home">
            <ViewModuleRoundedIcon />
          </Link>
          <Link underline="hover" color="neutral" fontSize={12} fontWeight={500}>
            Users
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            All
          </Typography>
        </Breadcrumbs>
      </Box>
      {!loading && (
        <Box sx={{ px: { xs: 2, md: 6 } }}>
          <Box
            sx={{
              display: 'flex',
              mb: 1,
              gap: 1,
              mt: 4,
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Typography level="h3" component="h1">
              Users
            </Typography>
            <Button color="primary" size="sm" onClick={handleButtonClick}>
              New User
            </Button>
          </Box>
        </Box>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Sheet
            className="OrderTableContainer"
            variant="outlined"
            sx={{
              display: { xs: 'none', sm: 'initial' },
              width: '80%',
              maxHeight: '80vh',
              minHeight: 0,
              borderRadius: 'sm',
              flexShrink: 1,
              overflowY: 'auto'
            }}
          >
            <Table
              aria-labelledby="tableTitle"
              stickyHeader
              hoverRow
              sx={{
                '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                '--Table-headerUnderlineThickness': '1px',
                '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                '--TableCell-paddingY': '4px',
                '--TableCell-paddingX': '8px',
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: 140, padding: '12px 6px' }}>First Name</th>
                  <th style={{ width: 140, padding: '12px 6px' }}>Last Name</th>
                  <th style={{ width: 140, padding: '12px 6px' }}>Email</th>
                  <th style={{ width: 140, padding: '12px 6px' }}>Profile</th>
                  <th style={{ width: 140, padding: '12px 6px' }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td>
                      <Typography level="body-xs">{user.firstName}</Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">{user.lastName}</Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">{user.email}</Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">{user.profile.label}</Typography>
                    </td>
                    <td>
                      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <RowMenu
                          menuItems={[
                            { label: 'Update', onClick: () => handleOpenModal(user.id), color: 'default' },
                            { label: 'Delete', onClick: () => console.log('Delete clicked'), color: 'danger' },
                          ]}
                        />
                      </Box>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        </Box>
      )}
      <UserForm
        open={isModalOpen}
        onClose={handleCloseModal}
        editUserId={selectedUser}
        showSnackBar={showSnackBar}
        handleSave={handleSave}
      />
    </React.Fragment>
  );
};

export default UserList;
