import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IWorkflow , NodeTypeEnum, loadWorkflow,determineNextNodeType  } from '../../../../../services/workflow/WorkflowService';


interface PaneContextProps {
  selectedNode: NodeTypeEnum; // Renamed from selectedPane to selectedNode
  setSelectedNode: (node: NodeTypeEnum) => void;
  workflow: IWorkflow | null;
  setWorkflow: (workflow: IWorkflow) => void;
  isPaneVisible: boolean;
  setIsPaneVisible: (isVisible: boolean) => void;
  nextNodeType: NodeTypeEnum;
  setNextNodeType: (type: NodeTypeEnum) => void;
}

const PaneContext = createContext<PaneContextProps | undefined>(undefined);

export const PaneProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { workflowId } = useParams<{ workflowId: string }>();
  const [selectedNode, setSelectedNode] = useState<NodeTypeEnum>(NodeTypeEnum.Trigger);
  const [workflow, setWorkflow] = useState<IWorkflow | null>(null);
  const [isPaneVisible, setIsPaneVisible] = useState(false);
  const [nextNodeType, setNextNodeType] = useState<NodeTypeEnum>(NodeTypeEnum.Trigger);

  useEffect(() => {
    const fetchWorkflow = async () => {
      if (workflowId) {
        try {
          const loadedWorkflow = await loadWorkflow(Number(workflowId))
          setWorkflow(loadedWorkflow)
          setNextNodeType(determineNextNodeType(loadedWorkflow))
        } catch (error) {
          console.error('Failed to load workflow:', error);
        }
      }
    };

    fetchWorkflow();
  }, [workflowId]);


  return (
    <PaneContext.Provider value={{
      selectedNode,
      setSelectedNode,
      workflow,
      setWorkflow,
      isPaneVisible,
      setIsPaneVisible,
      nextNodeType,
      setNextNodeType,
    }}>
      {children}
    </PaneContext.Provider>
  );
};

export const usePane = (): PaneContextProps => {
  const context = useContext(PaneContext);
  if (!context) {
    throw new Error('usePane must be used within a PaneProvider');
  }
  return context;
};
