import { IBProfile, IResource } from '../../interfaces/BProfile';
import { fetchData, saveData, updateData } from '../../middleware/api';

// Helper function to map payload to IBProfile
const mapToIBProfile = (payload: any): IBProfile => ({
  id: payload.id,
  label: payload.label,
  internalName: payload.internalName,
  description: payload.description,
  resourcePermissions: payload.resourcePermissions ?? {}
});

// Load a single BProfile
export const loadBProfile = async (profileId: string): Promise<IBProfile> => {
  const payload = await fetchData(`/_/admin/b-profiles/_/${profileId}/`);
  return mapToIBProfile(payload);
};

// Load all BProfiles
export const loadBProfiles = async (): Promise<IBProfile[]> => {
  const payload = await fetchData('/_/admin/b-profiles/_/?page=0&size=100');
  return payload.content.map((bProfile: any) => mapToIBProfile(bProfile));
};

// Save a new BProfile
export const saveBProfile = async (bProfile: IBProfile): Promise<IBProfile> => {
  const payload = await saveData('/_/admin/b-profiles/_/', bProfile);
  return mapToIBProfile(payload);
};

// Update an existing BProfile
export const updateBProfile = async (profileId: string, bProfile: IBProfile): Promise<IBProfile> => {
  const payload = await updateData(`/_/admin/b-profiles/_/${profileId}/`, bProfile);
  return mapToIBProfile(payload);
};

// Load all resources
export const loadResources = async (): Promise<IResource[]> => {
  const payload = await fetchData('/_/admin/b-profiles/_/resources/');
  return payload.map((resource: any) => ({    
    name: resource.name,
    label: resource.label,
    entityType: resource.entityType,
    entityId: resource.entityId
  }));
};
