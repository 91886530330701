import React from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';


const Action: React.FC = () => {
  return (
    <Box sx={{ width: '300px', p: 3 }}>
      <Typography level="h4" component="h2" mb={2}>
        Action Properties
      </Typography>            
    </Box>
  );
};

export default Action;
