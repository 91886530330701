import React from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import Link from '@mui/joy/Link';
import { useNavigate, useParams } from 'react-router-dom';
import MainEditor from './editor/MainEditor';
import PaneContainer from './editor/PropertiesPane/PaneContainer';
import { PaneProvider, usePane } from './context/PaneContext';
import { ReactFlowProvider } from '@xyflow/react';

const WorkflowEditorContent: React.FC = () => {
  const { isPaneVisible } = usePane();

  return (
    <Box sx={{ display: 'flex', height: '90%', mt: 1 }}> 
      <MainEditor />
      {isPaneVisible && <PaneContainer />}
    </Box>
  );
};

const WorkflowEditor: React.FC = () => {
  const navigate = useNavigate();
  const { workflowId } = useParams();
  const isNewWorkflow = !workflowId;

  return (
    <Box
      sx={{
        px: { xs: 2, md: 6 },
        mt: 2,
        border: '1px solid #1976d2', 
        borderRadius: '8px',
        padding: '16px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        height: 'calc(90vh - 40px)', // Calculate height to avoid overflow
        overflow: 'auto', // Ensure anything exceeding the height is scrollable
      }}
    >
      <Breadcrumbs
        size="sm"
        aria-label="breadcrumbs"
        separator={<ChevronRightRoundedIcon />}
        sx={{ pl: 0 }}
      >
        <Link underline="none" color="neutral" onClick={() => navigate('/workflows')}>
          Workflows
        </Link>
        <Typography color="primary" fontWeight={500} fontSize={12}>
          {isNewWorkflow ? 'Create' : `Edit ${workflowId}`}
        </Typography>
      </Breadcrumbs>
      <ReactFlowProvider>
        <PaneProvider>
          <WorkflowEditorContent />
        </PaneProvider>
      </ReactFlowProvider>
    </Box>
  );
};

export default WorkflowEditor;
