
import React, { useState,useEffect } from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { usePane } from '../../context/PaneContext';
import useTriggerClick from '../hooks/useTriggerClick';
import { NodeTypeEnum, WorkflowTriggerType } from '../../../../../../services/workflow/WorkflowService';

const TriggerProperties: React.FC = () => {
  const { workflow, setWorkflow, selectedNode, setIsPaneVisible, setSelectedNode } = usePane(); 
  const handleSaveClick = useTriggerClick('1'); 
  const [executeBasedOn, setExecuteBasedOn] = useState<WorkflowTriggerType>(WorkflowTriggerType.ON_CREATE); 
  
   // Effect to initialize executeBasedOn when the workflow is loaded or changed
   useEffect(() => {
    if (workflow && workflow.triggerType) {
      setExecuteBasedOn(workflow.triggerType);
    }
  }, [workflow]);


  const handleSave = () => {        
    if (workflow?.name) {       
      setWorkflow({
        ...workflow,
        triggerType: executeBasedOn
      });            
      handleSaveClick();
    } else {
      console.error("Workflow name is required.");
    }

    setIsPaneVisible(false)
    setSelectedNode(NodeTypeEnum.NONE)    
  };


  
  return (
    <Box sx={{ width: '300px', p: 3 }}>
      <Typography level="h4" component="h2" mb={2}>
        Choose Trigger
      </Typography>
      <Typography level="body-sm" mb={2}>
        A trigger defines an event that starts the workflow. You can choose to execute the workflow when an item is created, edited, or both.
      </Typography>
      <FormControl sx={{ mb: 3 }}>
        <FormLabel>Execute Based On</FormLabel>
        <Select
          value={executeBasedOn}
          onChange={(e, newValue) => setExecuteBasedOn(newValue as WorkflowTriggerType)}
          size="sm"
        >
          <Option value={WorkflowTriggerType.ON_CREATE}>Create</Option>
          <Option value={WorkflowTriggerType.ON_EDIT}>Edit</Option>
          <Option value={WorkflowTriggerType.ON_CREATE_OR_EDIT}>Create or Edit</Option>
        </Select>
      </FormControl>
      <Button variant="solid" color="primary" onClick={handleSave}>
        Done
      </Button>
    </Box>
  );
};

export default TriggerProperties;