import React, { useEffect, useState } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import { Textarea } from '@mui/joy';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Modal from '@mui/joy/Modal';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CircularProgress from '@mui/material/CircularProgress';
import { IBObject } from '../../../interfaces/BObject';
import { saveBObject, loadBObject, updateBObject } from '../../../services/b-object/BObjectService';
import { useAppNavigation } from '../../../hooks/useAppNavigation';

interface BObjectFormProps {
  open: boolean;
  onClose: () => void;
  internalName: string | null;
  showSnackBar: (message: string) => void;
}

const BObjectForm: React.FC<BObjectFormProps> = ({ open, onClose, internalName, showSnackBar }) => {
  const [bObject, setBObject] = useState<IBObject>({
    id: 0,
    label: '',
    pluralLabel: '',
    description: '',
    internalName: ''
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { generateNavigation } = useAppNavigation();

  useEffect(() => {
    if (internalName) {
      setLoading(true);
      loadBObject(internalName).then((data) => {
        setBObject(data);
        setLoading(false);
      });
    } else {
      setBObject({
        id: 0,
        label: '',
        pluralLabel: '',
        description: '',
        internalName: ''
      });
    }
  }, [internalName]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setBObject((prevBObject) => ({
      ...prevBObject,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      if (internalName) {
        await updateBObject(internalName, bObject);
        showSnackBar("Object Updated Successfully!!!")
      } else {
        const response = await saveBObject(bObject);
        showSnackBar("Object Created Successfully!!!")
        generateNavigation(`/objects/${response.internalName}/fields`)();
      }
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Card sx={{ width: { xs: '100%', md: '40%' }, p: 3, borderRadius: 'md', boxShadow: 'lg' }}>
        <Typography level="h4" component="h2" mb={2}>
          {internalName ? 'Edit Object' : 'Create Object'}
        </Typography>
        <Stack spacing={2}>
          <FormControl>
            <FormLabel>Label</FormLabel>
            <Input
              size="sm"
              placeholder="Lead"
              name="label"
              value={bObject.label}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Plural Label</FormLabel>
            <Input
              size="sm"
              placeholder="Leads"
              name="pluralLabel"
              value={bObject.pluralLabel}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Textarea
              size="sm"
              placeholder="Description"
              minRows={3}
              name="description"
              value={bObject.description}
              onChange={handleChange}
            />
          </FormControl>
        </Stack>
        <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button size="sm" variant="outlined" color="neutral" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            size="sm"
            variant="solid"
            onClick={handleSave}
            disabled={loading}
            startDecorator={loading && <CircularProgress size={16} />}
            sx={{ ml: 1 }}
          >
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default BObjectForm;
