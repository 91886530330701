import axios, {AxiosInstance} from 'axios';

const getBaseUrl = () => {
  const envDomain = process.env.REACT_APP_BASE_URL;
  return envDomain || `${window.location.origin}/lego`;
};

const axiosInstance : AxiosInstance = axios.create({
  baseURL: getBaseUrl(),
  withCredentials: true,
  headers: {
    'Authorization': `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
    'X-Client': 'OBZ-UI-APP'
  }
});

export const fetchData = async (endpoint: string) => {
  try {
    const response = await axiosInstance.get(`${endpoint}`, {
      data: {}
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching data');
  }
};

export const updateData = async (endpoint: string, data: any) => {
  try {
    const response = await axiosInstance.put(`${endpoint}`, data);
    return response.data;
  } catch (error) {
    throw new Error('Error updating data');
  }
};

export const saveData = async (endpoint: string, data: any) => {
  try {
    const response = await axiosInstance.post(`${endpoint}`, data);
    return response.data;
  } catch (error) {
    throw new Error('Error saving data');
  }
};
