import React, { useState } from 'react';
import Box from '@mui/joy/Box';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import SnackbarComponent from '../../ui/SnackbarComponent';
import BObjectList from './BObjectList';
import BFieldContainer from './b-field/BFieldContainer';
import BObjectForm from './BObjectForm';

const BObjectContainer: React.FC = () => {
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [editInternalName, setEditInternalName] = useState<string | null>(null);


  const handleOpenModal = (internalName: string | null) => {
    setEditInternalName(internalName);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setEditInternalName(null);
  };

  const showSnackBar = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  return (
    <React.Fragment>
      <Box>
        <Routes>
          <Route
            path="/"
            element={<BObjectList onOpenModal={handleOpenModal} showSnackBar={showSnackBar} />}
          />
          <Route
            path="/:objectName/fields/*"
            element={<BFieldContainer showSnackBar={showSnackBar}/>}
          />
        </Routes>
        {modalOpen && (
          <BObjectForm
            open={modalOpen}
            onClose={handleCloseModal}
            internalName={editInternalName}
            showSnackBar={showSnackBar}
          />
        )}
      </Box>
      <SnackbarComponent
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </React.Fragment>
  );
};

export default BObjectContainer;
