import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import Box from '@mui/joy/Box';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import AppsIcon from '@mui/icons-material/Apps';
import { IBFieldChoice } from '../../../../interfaces/BObject';

interface ChoiceItemProps {
  index: number;
  choice: IBFieldChoice;
  moveChoice: (dragIndex: number, hoverIndex: number) => void;
  handleChoiceChange: (index: number, value: string) => void;
  handleRemoveChoice: (index: number) => void;
  choicesLength: number;
}

const ChoiceItem: React.FC<ChoiceItemProps> = ({
  index,
  choice,
  moveChoice,
  handleChoiceChange,
  handleRemoveChoice,
  choicesLength,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: 'CHOICE',
    hover(item: { index: number }) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveChoice(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'CHOICE',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.5 : 1;

  drag(drop(ref));

  return (
    <Box ref={ref} display="flex" alignItems="center" mb={1} sx={{ opacity }}>
      <AppsIcon sx={{ cursor: 'grab', mr: 1 }} />
      <Input
        size="sm"
        placeholder={`Choice ${index + 1}`}
        value={choice.value}
        onChange={(e) => handleChoiceChange(index, e.target.value)}
        sx={{ flex: 1 }}
      />
      <Button
        size="sm"
        variant="outlined"
        color="danger"
        onClick={() => handleRemoveChoice(index)}
        sx={{ ml: 1 }}
        startDecorator={<DeleteIcon />}
        disabled={choicesLength <= 2}
      >
        Remove
      </Button>
    </Box>
  );
};

export default ChoiceItem;
