import React from 'react';
import TriggerProperties from './TriggerProperties';
import FilterProperties from './FilterProperties';
import Action from './Action';
import { usePane } from '../../context/PaneContext';
import { NodeTypeEnum } from '../../../../../../services/workflow/WorkflowService';
import { determineNextNodeType } from '../../../../../../services/workflow/WorkflowService';

const PaneContainer: React.FC = () => {
  const { selectedNode, workflow } = usePane();

  const renderSelectedPane = () => {    
    const nodeType = selectedNode !== NodeTypeEnum.NONE ? selectedNode : determineNextNodeType(workflow);    
    switch (nodeType) {
      case NodeTypeEnum.Filter:
        return <FilterProperties />;
      case NodeTypeEnum.Action:
        return <Action />;
      case NodeTypeEnum.Trigger:
      default:
        return <TriggerProperties />;
    }
  };
  return (
    <div
      style={{
        border: '1px solid #1976d2', // Primary border color (MUI's primary color)
        borderRadius: '8px', // Rounded corners
        zIndex: 100, // Higher z-index to bring it above other elements
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Shadow for depth
        backgroundColor: '#fff', // Background color for the container
      }}
    >
      {renderSelectedPane()}
    </div>
  );
};

export default PaneContainer;
