import { memo } from 'react';
import { Handle, Position, NodeProps, BuiltInNode } from '@xyflow/react';
import cx from 'classnames';
import React from 'react';
import styles from './NodeTypes.module.css';
import { usePane } from '../../context/PaneContext';
import { NodeTypeEnum } from '../../../../../../services/workflow/WorkflowService';
import { IFilter, IRule } from '../../../../../../services/workflow/WorkflowService';

const FilterNode = ({ id }: NodeProps<BuiltInNode>) => {
  const { workflow, setSelectedNode, setIsPaneVisible } = usePane();

  const filterNodeClick = () => {
    setSelectedNode(NodeTypeEnum.Filter);
    setIsPaneVisible(true);
  };

  const filter = workflow?.filter;

  const filterCondition: IRule | undefined = filter?.condition.rules[0]; // Assuming the first rule for simplicity
  const filterText = filterCondition
    ? `${filterCondition.field} ${filterCondition.operator} ${filterCondition.value}`
    : 'No filter applied';

  return (
    <div onClick={filterNodeClick} className={cx(styles.node)}>
      {/* Top right: Filter label as a chip */}
      <div
        className={styles.chip}
        style={{
          position: 'absolute',
          top: '4px',
          right: '4px',
          fontSize: '8px',
          padding: '2px 6px',
          borderRadius: '12px',
          backgroundColor: '#4caf50',  // Contrasting background color for filter
          color: '#ffffff',  // Contrasting text color
          display: 'inline-block',
          marginBottom: '2px',
        }}
      >
        Filter
      </div>

      {/* Below: Display the filter condition in a readable format */}
      <div
        className={styles.filterContent}
        style={{ fontSize: '8px', marginTop: '20px', textAlign: 'center' }}
      >
        <span>{filterText}</span>
      </div>

      <Handle
        className={styles.handle}
        type="target"
        position={Position.Top}
        isConnectable={false}
      />
      <Handle
        className={styles.handle}
        type="source"
        position={Position.Bottom}
        isConnectable={false}
      />
    </div>
  );
};

export default memo(FilterNode);
