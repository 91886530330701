import React, { useState, useEffect } from 'react';
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { Button, CircularProgress, Breadcrumbs } from '@mui/joy';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ViewModuleRoundedIcon from '@mui/icons-material/ViewModuleRounded';
import Link from '@mui/joy/Link';
import WorkflowForm from './WorkflowForm'; 
import RowMenu from '../../../ui/RowMenu';
import { loadWorkflows } from '../../../../services/workflow/WorkflowService';
import { IWorkflow } from '../../../../services/workflow/WorkflowService';
import { useAppNavigation } from '../../../../hooks/useAppNavigation';

interface WorkflowListProps {
  showSnackBar: (message: string) => void;
}

const WorkflowList: React.FC<WorkflowListProps> = ({ showSnackBar }) => {
  const [loading, setLoading] = useState(false);
  const { generateNavigation } = useAppNavigation();
  const [formOpen, setFormOpen] = useState(false); // State to control WorkflowForm modal
  const [workflows, setWorkflows] = useState<IWorkflow[]>([]); // State to hold workflows

  useEffect(() => {
    const fetchWorkflows = async () => {
      setLoading(true);
      try {
        const data = await loadWorkflows();
        setWorkflows(data);
      } catch (error) {
        showSnackBar('Failed to load workflows');
        console.error('Error loading workflows:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchWorkflows();
  }, []);

  const handleCreateNewWorkflow = () => {
    setFormOpen(true); // Open the WorkflowForm modal
  };

  const handleFormClose = () => {
    setFormOpen(false); // Close the WorkflowForm modal
  };

  return (
    <>
      <Box sx={{ px: { xs: 2, md: 6 }, mt: 2 }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" aria-label="Home">
            <ViewModuleRoundedIcon />
          </Link>
          <Link underline="hover" color="neutral" fontSize={12} fontWeight={500}>
            Workflows
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            All
          </Typography>
        </Breadcrumbs>
      </Box>
      {!loading && (
        <Box sx={{ px: { xs: 2, md: 6 } }}>
          <Box
            sx={{
              display: 'flex',
              mb: 1,
              gap: 1,
              mt: 4,
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Typography level="h3" component="h1">
              Workflows
            </Typography>
            <Button color="primary" size="sm" onClick={handleCreateNewWorkflow}>
              New Workflow
            </Button>
          </Box>
        </Box>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Sheet
            className="OrderTableContainer"
            variant="outlined"
            sx={{
              display: { xs: 'none', sm: 'initial' },
              width: '80%',
              maxHeight: '80vh',
              minHeight: 0,
              borderRadius: 'sm',
              flexShrink: 1,
              overflowY: 'auto',
            }}
          >
            <Table
              aria-labelledby="tableTitle"
              stickyHeader
              hoverRow
              sx={{
                '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                '--Table-headerUnderlineThickness': '1px',
                '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                '--TableCell-paddingY': '4px',
                '--TableCell-paddingX': '8px',
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: 140, padding: '12px 6px' }}>BObject</th> 
                  <th style={{ width: 140, padding: '12px 6px' }}>Name</th>
                  <th style={{ width: 240, padding: '12px 6px' }}>Description</th>                  
                  <th style={{ width: 140, padding: '12px 6px' }}>Status</th>
                  <th style={{ width: 140, padding: '12px 6px' }}> </th>
                </tr>
              </thead>
              <tbody>
                {workflows.map((workflow) => (
                  <tr key={workflow.id}>
                    <td>
                      <Typography level="body-xs">{workflow.bobject.label}</Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">{workflow.name}</Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">{workflow.description}</Typography>
                    </td>                    
                    <td>
                      <Typography level="body-xs">{workflow.status}</Typography>
                    </td>
                    <td>
                      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <Link
                          level="body-xs"
                          component="button"                          
                          onClick={() =>
                            generateNavigation(`/workflows/${workflow.id}/edit`)()
                          }
                        >
                          Edit
                        </Link>
                        <RowMenu
                          menuItems={[
                            { label: 'Edit Workflow', onClick: () => console.log('Edit Workflow', workflow.id), color: 'default' },
                            { label: 'Delete', onClick: () => console.log('Delete clicked'), color: 'danger' },
                          ]}
                        />
                      </Box>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        </Box>
      )}
      <WorkflowForm open={formOpen} onClose={handleFormClose} showSnackBar={showSnackBar} />
    </>
  );
};

export default WorkflowList;
