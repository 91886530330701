import { fetchData, saveData } from '../../middleware/api';

export interface IBObject {
  id: number;
  internalName: string;
  label: string;
  pluralLabel: string;
  description: string;
}

export interface IWorkflow {
  id?: number;
  name?: string;
  description?: string;
  bobject: IBObject;
  triggerType?: WorkflowTriggerType;
  condition?: string;
  status?: string;
  workflowActions?: string[];
  filter?: IFilter;
}

export interface IFilter {
  condition: IFilterCondition;  
}

export interface IFilterCondition {
  condition: string;
  rules: IRule[];
}

export interface IRule {
  field: string;
  operator: string;
  type: string;
  value: any;
  condition?: string;
  rules?: IRule[];
}

export interface IWorkflowCreate {
  id?: number;
  name: string;
  description?: string;
  bobjectId: number;
  triggerType?: WorkflowTriggerType;
  condition?: string;
  status?: string;
}
  
  export enum WorkflowTriggerType {
    ON_CREATE = "ON_CREATE",
    ON_EDIT = "ON_EDIT",
    ON_CREATE_OR_EDIT = "ON_CREATE_OR_EDIT",
  }  

// Load an existing workflow by ID
export const loadWorkflow = async (workflowId: number): Promise<IWorkflow> => {
  const endpoint = `/_/admin/workflows/_/${workflowId}/`;
  try {
    const data = await fetchData(endpoint);
    return data as IWorkflow;
  } catch (error) {
    throw new Error('Error fetching workflow');
  }
};

// Save a new workflow
export const saveWorkflow = async (workflow: IWorkflowCreate): Promise<IWorkflow> => {
  const endpoint = '/_/admin/workflows/_/';
  try {
    const payload = await saveData(endpoint, workflow);
    return payload as IWorkflow;
  } catch (error) {
    throw new Error('Error saving workflow');
  }
};


// Load workflows from the server
export const loadWorkflows = async (): Promise<IWorkflow[]> => {
  const endpoint = '/_/admin/workflows/_/?page=0&size=10';
  try {
    const data = await fetchData(endpoint);
    return data.content as IWorkflow[];
  } catch (error) {
    throw new Error('Error fetching workflows');
  }
};


export enum NodeTypeEnum {
  Trigger = 'Trigger',
  Filter = 'Filter',
  Action = 'Action',
  NONE = 'NONE'
}

export const determineNextNodeType = (workflow: IWorkflow | null): NodeTypeEnum => {
  if (!workflow) return NodeTypeEnum.NONE;
  if (!workflow.triggerType) return NodeTypeEnum.Trigger;
  if (!workflow.filter) return NodeTypeEnum.Filter;
  return NodeTypeEnum.Action; 
};

