import React, { useEffect } from 'react';
import { Snackbar, Button } from '@mui/joy';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

interface SnackbarComponentProps {
  open: boolean;
  onClose: () => void;
  message: string;
}

const SnackbarComponent: React.FC<SnackbarComponentProps> = ({ open, onClose, message }) => {
  useEffect(() => {
    if (open) {
      const timer = setTimeout(onClose, 3000);
      return () => clearTimeout(timer);
    }
  }, [open, onClose]);

  return (
    <Snackbar
      color="success"
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      startDecorator={<CheckCircleRoundedIcon />}
      endDecorator={
        <Button onClick={onClose} size="sm" variant="soft" color="neutral">
          Dismiss
        </Button>
      }
    >
      {message}
    </Snackbar>
  );
};

export default SnackbarComponent;
