import React, { useState } from 'react';
import Box from '@mui/joy/Box';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';

import BFieldList from './BFieldList';
interface BFieldContainerProps {  
  showSnackBar: (message: string) => void;
}


const BFieldContainer: React.FC<BFieldContainerProps> = ({showSnackBar}) => {

  
  const { objectName } = useParams<{ objectName: string }>();
  const navigate = useNavigate();
  
  return (
    <React.Fragment>
      <Box>
        <Routes>
          <Route
            path="/"
            element={<BFieldList objectName={objectName!} showSnackBar={showSnackBar} />}
          />
        </Routes>
      </Box>     
    </React.Fragment>
  );
};

export default BFieldContainer;
