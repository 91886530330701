import React, { useState } from 'react';
import Box from '@mui/joy/Box';
import { Route, Routes } from 'react-router-dom';
import SnackbarComponent from '../../ui/SnackbarComponent';
import BProfileList from './BProfileList';
import BProfileResourcePermission from './BProfileResourcePermission'; // Make sure the path is correct

const BProfileContainer: React.FC = () => {
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [editInternalName, setEditInternalName] = useState<string | null>(null);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleOpenModal = (internalName: string | null) => {
    setEditInternalName(internalName);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setEditInternalName(null);
  };

  const showSnackBar = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  return (
    <React.Fragment>
      <Box>
        <Routes>
          <Route
            path="/"
            element={<BProfileList onOpenModal={handleOpenModal} showSnackBar={showSnackBar} />}
          />
          <Route
            path="/:profileId/resources"
            element={<BProfileResourcePermission showSnackBar={showSnackBar}/>}
          />
        </Routes>
      </Box>
      <SnackbarComponent
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </React.Fragment>
  );
};

export default BProfileContainer;
