import { NodeProps, useReactFlow,useStoreApi } from '@xyflow/react';
import { uuid } from '../utils';
import { usePane } from '../../context/PaneContext';

export function useTriggerClick(id: NodeProps['id']) {
  const { getNode, setNodes, setEdges, getNodes } = useReactFlow();
  const store = useStoreApi();
  const { setIsPaneVisible } = usePane();
  const reactFlowInstance = useReactFlow();

  const onClick = () => {
    // Check if there is already a node of type 'trigger'
    const existingTriggerNode = getNodes().find(node => node.type === 'trigger');

    const { nodeLookup } = store.getState();
    const nodes = Array.from(nodeLookup).map(([, node]) => node);

    console.log("nodes ==> " + JSON.stringify(nodes));
    
    if (existingTriggerNode) {
      console.log('A trigger node already exists. Action aborted.');
      setIsPaneVisible(false)
      return; // Exit early if a trigger node is already present
    }
    console.log(" getting the node to calculate the parent node " + id )
    // Get the parent node object for its position
    const parentNode = getNode(id);
    
    if (!parentNode) {
      return;
    }

    // Create a unique id for the placeholder node that will be added as a child of the clicked node
    const childPlaceholderId = uuid();

    // Create a placeholder node that will be added as a child of the clicked node
    const childPlaceholderNode = {
      id: childPlaceholderId,
      position: { x: parentNode.position.x, y: parentNode.position.y },
      type: 'placeholder',
      data: { label: '+' },
    };

    // We need a connection from the clicked node to the new placeholder
    const childPlaceholderEdge = {
      id: `${parentNode.id}=>${childPlaceholderId}`,
      source: parentNode.id,
      target: childPlaceholderId,
      type: 'placeholder',
    };

    setNodes((nodes) =>
      nodes
        .map((node) => {
          // Change the type of the clicked node from placeholder to trigger
          if (node.id === id) {
            return {
              ...node,
              type: 'trigger',
              data: { label: 'trigger' },
            };
          }
          return node;
        })
        // Add the new placeholder node
        .concat([childPlaceholderNode])
    );

    setEdges((edges) =>
      edges
        .map((edge) => {
          // Change the type of the connecting edge from placeholder to workflow
          if (edge.target === id) {
            return {
              ...edge,
              type: 'workflow',
            };
          }
          return edge;
        })
        // Add the new placeholder edge
        .concat([childPlaceholderEdge])
    );       
    setIsPaneVisible(false) 
    setTimeout(() => {        
        reactFlowInstance.fitView()
    }, 400);
  };   
  return onClick;
}

export default useTriggerClick;
