import React, { useState } from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import { Routes, Route } from 'react-router-dom';
import Sidebar from './components/ui/Sidebar';
import TopNavbar from './components/ui/TopNavbar';
import BObjectContainer from './components/modules/b-object/BObjectContainer';
import BProfileContainer from './components/modules/b-profile/BProfileContainer';
import UserContainer from './components/modules/user/UserContainer';
import WorkflowContainer from './components/modules/automations/workflow/WorkflowContainer';

const App: React.FC = () => {
  const [sliderOpen, setSliderOpen] = useState(false);
  const [appMenuOpen, setAppMenuOpen] = useState(false);

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
        <Sidebar
          sliderOpen={sliderOpen}
          setSliderOpen={setSliderOpen}
          appMenuOpen={appMenuOpen}
          setAppMenuOpen={setAppMenuOpen}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <TopNavbar toggleSlider={() => setSliderOpen(!sliderOpen)} appMenuOpen={appMenuOpen} />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              pt: '80px', // Adjust for TopNavbar height
              px: { xs: 2, md: 6 },
              position: 'relative',
            }}
          >
            <Routes>
              <Route path="/objects/*" element={<BObjectContainer />} />
              <Route path="/profiles/*" element={<BProfileContainer />} />
              <Route path="/users/*" element={<UserContainer />} />
              <Route path="/workflows/*" element={<WorkflowContainer />} />
            </Routes>
            {appMenuOpen && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  zIndex: 10000,
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </CssVarsProvider>
  );
};

export default App;
