import React, { useState } from 'react';
import Box from '@mui/joy/Box';
import { Route, Routes } from 'react-router-dom';
import SnackbarComponent from '../../ui/SnackbarComponent';
import UserList from './UserList';

const UserContainer: React.FC = () => {
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  const showSnackBar = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  return (
    <React.Fragment>
      <Box>
        <Routes>
          <Route
            path="/"
            element={<UserList showSnackBar={showSnackBar} />}
          />
        </Routes>
      </Box>
      <SnackbarComponent
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </React.Fragment>
  );
};

export default UserContainer;
