import React, { useEffect, useState } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import { Textarea } from '@mui/joy';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Modal from '@mui/joy/Modal';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CircularProgress from '@mui/material/CircularProgress';
import { IBProfile } from '../../../interfaces/BProfile';
import { loadBProfile, saveBProfile, updateBProfile } from '../../../services/b-profile/BProfileService';
import { useAppNavigation } from '../../../hooks/useAppNavigation';
import { convertToInternalName } from '../../../utils/CommonUtils';

interface BProfileFormProps {
  open: boolean;
  onClose: () => void;
  profileId: string | null;
  showSnackBar: (message: string) => void;
}

const BProfileForm: React.FC<BProfileFormProps> = ({ open, onClose, profileId, showSnackBar }) => {
  const [bProfile, setBProfile] = useState<IBProfile>({
    id: '',
    label: '',
    internalName: '',
    description: ''
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { generateNavigation } = useAppNavigation();

  useEffect(() => {
    if (profileId) {
      setLoading(true);
      loadBProfile(profileId).then((data) => {
        setBProfile(data);
        setLoading(false);
      }).catch(() => setLoading(false));
    } else {
      setBProfile({
        id: '',
        label: '',
        internalName: '',
        description: ''
      });
    }
  }, [profileId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setBProfile((prevBProfile) => ({
      ...prevBProfile,
      [name]: value,
      internalName: name === 'label' ? convertToInternalName(value) : prevBProfile.internalName
    }));
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      if (profileId) {
        await updateBProfile(profileId, bProfile);
        showSnackBar("Profile Updated Successfully!!!");
      } else {
        const response = await saveBProfile(bProfile);
        showSnackBar("Profile Created Successfully!!!");
        generateNavigation(`/profiles/${response.id}/resources`)();
      }
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Card sx={{ width: { xs: '100%', md: '40%' }, p: 3, borderRadius: 'md', boxShadow: 'lg' }}>
        <Typography level="h4" component="h2" mb={2}>
          {profileId ? 'Edit Profile' : 'Create Profile'}
        </Typography>
        <Stack spacing={2}>
          <FormControl>
            <FormLabel>Label</FormLabel>
            <Input
              size="sm"
              placeholder="Profile Label"
              name="label"
              value={bProfile.label}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Textarea
              size="sm"
              placeholder="Description"
              minRows={3}
              name="description"
              value={bProfile.description}
              onChange={handleChange}
            />
          </FormControl>
        </Stack>
        <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button size="sm" variant="outlined" color="neutral" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            size="sm"
            variant="solid"
            onClick={handleSave}
            disabled={loading}
            startDecorator={loading && <CircularProgress size={16} />}
            sx={{ ml: 1 }}
          >
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default BProfileForm;
