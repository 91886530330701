import { useEffect, useState } from 'react';
import { IUser } from '../../interfaces/User';
import { loadUsers } from '../../services/user/UserService';

export const useFetchUsers = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    loadUsers()
      .then((data) => {
        setUsers(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  return { users, loading, setUsers };
};