import React, { useState } from 'react';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import Link from '@mui/joy/Link';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Button, CircularProgress, Breadcrumbs } from '@mui/joy';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ViewModuleRoundedIcon from '@mui/icons-material/ViewModuleRounded';
import { ColorPaletteProp } from '@mui/joy';
import { useFetchBObjects } from '../../../hooks/b-object/useFetchBObjects';
import { useAppNavigation } from '../../../hooks/useAppNavigation';
import RowMenu from '../../ui/RowMenu';
import BObjectPermission from './BObjectPermission';

interface BObjectListProps {
  onOpenModal: (internalName: string | null) => void;
  showSnackBar: (message: string) => void;
}

const BObjectList: React.FC<BObjectListProps> = ({ onOpenModal, showSnackBar }) => {
  const { bObjects, loading } = useFetchBObjects();
  const { generateNavigation } = useAppNavigation();
  const [permissionOpen, setPermissionOpen] = useState<boolean>(false);
  const [selectedInternalName, setSelectedInternalName] = useState<string | null>(null);

  const handleButtonClick = () => {
    onOpenModal(null);
  };

  const handleOpenPermissionModal = (internalName: string) => {
    setSelectedInternalName(internalName);
    setPermissionOpen(true);
  };

  const handleClosePermissionModal = () => {
    setPermissionOpen(false);
    setSelectedInternalName(null);
  };

  return (
    <React.Fragment>
      <Box sx={{ px: { xs: 2, md: 6 }, mt: 2 }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" aria-label="Home">
            <ViewModuleRoundedIcon />
          </Link>
          <Link underline="hover" color="neutral" fontSize={12} fontWeight={500}>
            Objects
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            All
          </Typography>
        </Breadcrumbs>
      </Box>
      {!loading && (
        <Box sx={{ px: { xs: 2, md: 6 } }}>
          <Box
            sx={{
              display: 'flex',
              mb: 1,
              gap: 1,
              mt: 4,
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Typography level="h3" component="h1">
              Objects
            </Typography>
            <Button color="primary" size="sm" onClick={handleButtonClick}>
              New Object
            </Button>
          </Box>
        </Box>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Sheet
            className="OrderTableContainer"
            variant="outlined"
            sx={{
              display: { xs: 'none', sm: 'initial' },
              width: '80%',
              maxHeight: '80vh',
              minHeight: 0,
              borderRadius: 'sm',
              flexShrink: 1,
              overflowY: 'auto'  
            }}
          >
            <Table
              aria-labelledby="tableTitle"
              stickyHeader
              hoverRow
              sx={{
                '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                '--Table-headerUnderlineThickness': '1px',
                '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                '--TableCell-paddingY': '4px',
                '--TableCell-paddingX': '8px',
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: 140, padding: '12px 6px' }}>Name</th>
                  <th style={{ width: 140, padding: '12px 6px' }}>Internal Name</th>
                  <th style={{ width: 140, padding: '12px 6px' }}>Description</th>
                  <th style={{ width: 140, padding: '12px 6px' }}>Status</th>
                  <th style={{ width: 240, padding: '12px 6px' }}>Last Accessed</th>
                  <th style={{ width: 140, padding: '12px 6px' }}> </th>
                </tr>
              </thead>
              <tbody>
                {bObjects.map((bObject) => (
                  <tr key={bObject.id}>
                    <td>
                      <Typography level="body-xs">{bObject.label}</Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">{bObject.internalName}</Typography>
                    </td>
                    <td>
                      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <div>
                          <Typography level="body-xs">{bObject.description}</Typography>
                        </div>
                      </Box>
                    </td>
                    <td>
                      <Chip
                        variant="soft"
                        size="sm"
                        startDecorator={<CheckRoundedIcon />}
                        color={'success' as ColorPaletteProp}
                      >
                        Active
                      </Chip>
                    </td>
                    <td>
                      <Typography level="body-xs">Jan 1, 2023</Typography>
                    </td>
                    <td>
                      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <Link
                          level="body-xs"
                          component="button"
                          onClick={() =>
                            generateNavigation(`/objects/${bObject.internalName}/fields`, {
                              bObject: bObject,
                            })()
                          }
                        >
                          Edit Fields
                        </Link>
                        <RowMenu
                          menuItems={[
                            { label: 'Set Permission', onClick: () => handleOpenPermissionModal(bObject.internalName), color: 'default' },
                            { label: 'Edit Properties', onClick: () => onOpenModal(bObject.internalName), color: 'default' },
                            { label: 'Delete', onClick: () => console.log('Delete clicked'), color: 'danger' },
                          ]}
                        />
                      </Box>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        </Box>
      )}
      {selectedInternalName && (
        <BObjectPermission
          open={permissionOpen}
          onClose={handleClosePermissionModal}
          internalName={selectedInternalName}
          showSnackBar={showSnackBar}
        />
      )}
    </React.Fragment>
  );
};

export default BObjectList;
