import { IBField } from '../../interfaces/BObject';
import { fetchData, saveData, updateData } from '../../middleware/api';

export const loadBFields = async (objectName: string): Promise<IBField[]> => {
  const payload = await fetchData(`/_/admin/b-objects/_/${objectName}/b-fields/`);
  return payload.map((bField: any) => {
    const { id, label, internalName, defaultValue, description, fieldType, required, unique, permission, displayOrder } = bField;
    return { id, label, internalName, defaultValue, description, fieldType, required, unique, permission, displayOrder };
  });
};

export const loadBField = async (objectName: string, fieldInternalName: string): Promise<IBField> => {
  const payload = await fetchData(`/_/admin/b-objects/_/${objectName}/b-fields/${fieldInternalName}/`);
  const { id, label, internalName, required, helpText, fieldType, unique, choices, additionalInfo, permission, displayOrder } = payload;
  return { id, label, internalName, required, helpText, fieldType, unique, choices, additionalInfo, permission, displayOrder };
};

export const saveBField = async (objectName: string, field: IBField): Promise<any> => {
  const internalName = field.label
    .trim()
    .replace(/\s+$/, '')
    .replace(/\s+/g, '_')
    .replace(/[^a-z_]/gi, '')
    .toLowerCase();
  const { label, fieldType, required, unique, choices, additionalInfo } = field;
  const data = [{ internalName, label, fieldType, required, unique, choices, additionalInfo }];
  return await saveData(`/_/admin/b-objects/_/${objectName}/b-fields/`, data);
};

export const updateBField = async (objectName: string, field: IBField): Promise<any> => {
  const { label, required, unique, choices, additionalInfo } = field;
  return await updateData(`/_/admin/b-objects/_/${objectName}/b-fields/${field.internalName}/`, {
    label, required, unique, choices, additionalInfo
  });
};

export const saveBFieldPermission = async (objectName: string, fieldName: string, permissions: { [key: number]: string }) => {
  return await updateData(`/_/admin/b-objects/_/${objectName}/b-fields/${fieldName}/permissions/`, permissions);
};

export const updateBFieldOrder = async (objectName: string, fieldOrders: { internalName: string, displayOrder: number }[]): Promise<any> => {
  const displayOrderMap = fieldOrders.reduce((acc, { internalName, displayOrder }) => {
    acc[internalName] = displayOrder+1;
    return acc;
  }, {} as Record<string, number>);

  return await updateData(`/_/admin/b-objects/_/${objectName}/b-fields/display-order`, displayOrderMap);
};
