import { IBObject, IBField } from '../../interfaces/BObject';
import { fetchData, saveData, updateData } from '../../middleware/api';
import { convertToInternalName } from '../../utils/CommonUtils';

export const loadBObjects = async (): Promise<IBObject[]> => {
  const payload = await fetchData('/_/admin/b-objects/_/?page=0&size=100');
  return payload.content.map((bObject: any) => ({
    id: bObject.id,
    label: bObject.label,
    description: bObject.description,
    internalName: bObject.internalName
  }));
};

export const loadBObject = async (objectName: string): Promise<IBObject> => {
  const payload = await fetchData(`/_/admin/b-objects/_/${objectName}/`);
  return {
    id: payload.id,
    label: payload.label,
    pluralLabel: payload.pluralLabel,
    description: payload.description,
    internalName: payload.internalName,
    permission: payload.permission,
  };
};

export const loadBFields = async (objectName: string): Promise<IBField[]> => {
  const endpoint = `/_/admin/b-objects/_/${objectName}/b-fields/`;
  try {
    const data = await fetchData(endpoint);
    return data as IBField[]; 
  } catch (error) {
    throw new Error('Error fetching BFields');
  }
};


export const saveBObject = async (bObject: IBObject): Promise<any> => {
  const internalName = convertToInternalName(bObject.label);    
  const { label, description, pluralLabel } = bObject;
  return await saveData('/_/admin/b-objects/_/', { label, pluralLabel, description, internalName });
};

export const saveBField = async (objectName: string, field: IBField) => {
  const internalName = convertToInternalName(field.label); 
  const { label, fieldType, helpText, required, unique } = field;
  const data = [{
    internalName,
    label,
    fieldType: { name: fieldType },
    description: label,
    helpText,
    required,
    unique,
  }];
  return await saveData(`/_/admin/b-objects/_/${objectName}/b-fields/`, data);
};

export const saveBObjectPermission = async (internalName: string, permissions: { [key: number]: string[] }): Promise<void> => {
  const url = `/_/admin/b-objects/_/${internalName}/permissions/`;
  await updateData(url, permissions);
};

export const updateBObject = async (objectName: string, bObject: IBObject): Promise<any> => {
  const { label, description, pluralLabel } = bObject;
  return await updateData(`/_/admin/b-objects/_/${objectName}/`, { label, pluralLabel, description });
};
