import { memo } from 'react';
import { Handle, Position, NodeProps, BuiltInNode } from '@xyflow/react';
import cx from 'classnames';
import React from 'react';
import { usePane } from '../../context/PaneContext';
import styles from './NodeTypes.module.css';
import { NodeTypeEnum } from '../../../../../../services/workflow/WorkflowService';

const PlaceholderNode = ({ id, data }: NodeProps<BuiltInNode>) => {
  const { setIsPaneVisible, setSelectedNode } = usePane(); 

  const onClick = () => {   
    setSelectedNode(NodeTypeEnum.NONE)
    setIsPaneVisible(true)  
    
  };

  const nodeClasses = cx(styles.node, styles.placeholder);

  return (
    <div onClick={onClick} className={nodeClasses} title="click to add a node">
      {data.label}
      <Handle
        className={styles.handle}
        type="target"
        position={Position.Top}
        isConnectable={false}
      />
      <Handle
        className={styles.handle}
        type="source"
        position={Position.Bottom}
        isConnectable={false}
      />
    </div>
  );
};

export default memo(PlaceholderNode);
