import { useState, useEffect } from 'react';
import { IBField } from '../../interfaces/BObject';
import { loadBFields } from '../../services/b-object/BFieldService';

export const useFetchBFields = (objectName: string) => {
  const [bFields, setBFields] = useState<IBField[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchFields = async () => {
      setLoading(true);
      try {
        const fields = await loadBFields(objectName);
        setBFields(fields.sort((a, b) => (a.displayOrder || 0) - (b.displayOrder || 0)));
      } catch (error) {
        console.error('Error fetching fields:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFields();
  }, [objectName]);

  return { bFields, setBFields, loading };
};
