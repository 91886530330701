import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography, Table, Sheet, Checkbox, Button, Breadcrumbs, Link, Snackbar } from '@mui/joy';
import { useParams } from 'react-router-dom';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ViewModuleRoundedIcon from '@mui/icons-material/ViewModuleRounded';
import { loadBProfile, loadResources, updateBProfile } from '../../../services/b-profile/BProfileService';
import { IBProfile, IResource } from '../../../interfaces/BProfile';
import { useAppNavigation } from '../../../hooks/useAppNavigation';

interface BProfileResourcePermissionProps {
    showSnackBar: (message: string) => void;
  }

const BProfileResourcePermission: React.FC<BProfileResourcePermissionProps> = ({showSnackBar}) => {
  const { profileId } = useParams<{ profileId: string }>();
  const [resources, setResources] = useState<IResource[]>([]);
  const [profile, setProfile] = useState<IBProfile | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [permissions, setPermissions] = useState<{ [key: string]: string[] }>({});
  const { generateNavigation } = useAppNavigation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  useEffect(() => {
    const fetchResourcesAndProfile = async () => {
      setLoading(true);
      try {
        const [resourcesResponse, profileResponse] = await Promise.all([
          loadResources(),
          loadBProfile(profileId!),
        ]);
        setResources(resourcesResponse);
        setProfile(profileResponse);
        initializePermissions(profileResponse.resourcePermissions ?? [], resourcesResponse);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError('An unknown error occurred');
        }
      } finally {
        setLoading(false);
      }
    };
    fetchResourcesAndProfile();
  }, [profileId]);

  const initializePermissions = (profilePermissions: { internalName: string; access: string[] }[], resources: IResource[]) => {
    const initialPermissions: { [key: string]: string[] } = {};
    resources.forEach((resource) => {
      const resourcePermission = profilePermissions.find((perm) => perm.internalName === resource.name);
      if (resourcePermission) {
        initialPermissions[resource.name] = resourcePermission.access;
      } else {
        initialPermissions[resource.name] = ['NO_ACCESS'];
      }
    });
    setPermissions(initialPermissions);
  };

  const handlePermissionChange = (resourceName: string, permission: string) => {
    setPermissions((prevPermissions) => {
      const currentPermissions = prevPermissions[resourceName] || [];
      let updatedPermissions;
      if (currentPermissions.includes(permission)) {
        updatedPermissions = currentPermissions.filter((perm) => perm !== permission);
      } else {
        updatedPermissions = [...currentPermissions, permission];
      }

      if (updatedPermissions.includes('NO_ACCESS') && updatedPermissions.length > 1) {
        updatedPermissions = updatedPermissions.filter((perm) => perm !== 'NO_ACCESS');
      } else if (updatedPermissions.length === 0) {
        updatedPermissions = ['NO_ACCESS'];
      }

      return {
        ...prevPermissions,
        [resourceName]: updatedPermissions,
      };
    });
  };

  const handleSave = async () => {
    if (profile) {
      const updatedProfile = {
        ...profile,
        resourcePermissions: Object.entries(permissions).map(([internalName, access]) => ({
          internalName,
          access,
        })),
      };
      try {
        await updateBProfile(profile.id, updatedProfile);
        showSnackBar('Permissions updated successfully!');
        generateNavigation('/profiles')();
      } catch (error) {
        showSnackBar('Failed to update permissions.');        
      } finally {
        setSnackbarOpen(true);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="danger">{error}</Typography>;
  }

  return (
    <Box>
      <Box sx={{ px: { xs: 2, md: 6 }, mt: 2 }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" aria-label="Home" onClick={() => generateNavigation('/')()}>
            <ViewModuleRoundedIcon />
          </Link>
          <Link underline="hover" color="neutral" fontSize={12} fontWeight={500} onClick={() => generateNavigation('/profiles')()}>
            Profiles
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            {profile?.label}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box sx={{ px: { xs: 2, md: 6 } }}>
        <Box
          sx={{
            display: 'flex',
            mb: 4,
            gap: 1,
            mt: 4,
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'start', sm: 'center' },
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <Typography level="h3" component="h1">
            {profile?.label}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Sheet
          className="PermissionTableContainer"
          variant="outlined"
          sx={{
            width: '80%',
            borderRadius: 'sm',
            flexShrink: 1,
            overflow: 'auto',
            minHeight: 0,
          }}
        >
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
              '--Table-headerUnderlineThickness': '1px',
              '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
              '--TableCell-paddingY': '4px',
              '--TableCell-paddingX': '8px',
            }}
          >
            <thead>
              <tr>
                <th>Resource Name</th>
                <th>View</th>
                <th>Create</th>
                <th>Update</th>
                <th>Delete</th>
                <th>No Access</th>
              </tr>
            </thead>
            <tbody>
              {resources.map((resource) => (
                <tr key={resource.name}>
                  <td>{resource.label}</td>
                  <td>
                    <Checkbox
                      checked={permissions[resource.name]?.includes('VIEW') || false}
                      onChange={() => handlePermissionChange(resource.name, 'VIEW')}
                    />
                  </td>
                  <td>
                    <Checkbox
                      checked={permissions[resource.name]?.includes('CREATE') || false}
                      onChange={() => handlePermissionChange(resource.name, 'CREATE')}
                    />
                  </td>
                  <td>
                    <Checkbox
                      checked={permissions[resource.name]?.includes('UPDATE') || false}
                      onChange={() => handlePermissionChange(resource.name, 'UPDATE')}
                    />
                  </td>
                  <td>
                    <Checkbox
                      checked={permissions[resource.name]?.includes('DELETE') || false}
                      onChange={() => handlePermissionChange(resource.name, 'DELETE')}
                    />
                  </td>
                  <td>
                    <Checkbox
                      checked={permissions[resource.name]?.includes('NO_ACCESS') || false}
                      onChange={() => handlePermissionChange(resource.name, 'NO_ACCESS')}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Sheet>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Box
          sx={{
            width: '80%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button variant="plain" color="neutral" onClick={() => generateNavigation('/profiles')()}>
            Cancel
          </Button>
          <Button variant="solid" sx={{ ml: 1 }} onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>      
    </Box>
  );
};

export default BProfileResourcePermission;
