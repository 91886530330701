import React, { useState } from 'react';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import Link from '@mui/joy/Link';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { Button, CircularProgress, Breadcrumbs } from '@mui/joy';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ViewModuleRoundedIcon from '@mui/icons-material/ViewModuleRounded';
import { useFetchBProfiles } from '../../../hooks/b-profile/useFetchBProfile';
import { useAppNavigation } from '../../../hooks/useAppNavigation';
import RowMenu from '../../ui/RowMenu';
import BProfileForm from './BProfileForm';


interface BProfileListProps {
  onOpenModal: (internalName: string | null) => void;
  showSnackBar: (message: string) => void;
}

const BProfileList: React.FC<BProfileListProps> = ({ onOpenModal, showSnackBar }) => {
  const { bProfiles, loading } = useFetchBProfiles();
  const { generateNavigation } = useAppNavigation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedProfile, setSelectedProfile] = useState<string | null>(null);

  const handleButtonClick = () => {
    setSelectedProfile(null);
    setIsModalOpen(true);
  };

  const handleResourcePermissionsClick = (profileId: string) => {
    generateNavigation(`/profiles/${profileId}/resources`)();
  };

  const handleOpenModal = (internalName: string | null) => {
    setSelectedProfile(internalName);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      <Box sx={{ px: { xs: 2, md: 6 }, mt: 2 }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" aria-label="Home">
            <ViewModuleRoundedIcon />
          </Link>
          <Link underline="hover" color="neutral" fontSize={12} fontWeight={500}>
            Profiles
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            All
          </Typography>
        </Breadcrumbs>
      </Box>
      {!loading && (
        <Box sx={{ px: { xs: 2, md: 6 } }}>
          <Box
            sx={{
              display: 'flex',
              mb: 1,
              gap: 1,
              mt: 4,
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Typography level="h3" component="h1">
              Profiles
            </Typography>
            <Button color="primary" size="sm" onClick={handleButtonClick}>
              New Profile
            </Button>
          </Box>
        </Box>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Sheet
            className="OrderTableContainer"
            variant="outlined"
            sx={{
              display: { xs: 'none', sm: 'initial' },
              width: '80%',
              maxHeight: '80vh',
              minHeight: 0,
              borderRadius: 'sm',
              flexShrink: 1,
              overflowY: 'auto'              
            }}
          >
            <Table
              aria-labelledby="tableTitle"
              stickyHeader
              hoverRow
              sx={{
                '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                '--Table-headerUnderlineThickness': '1px',
                '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                '--TableCell-paddingY': '4px',
                '--TableCell-paddingX': '8px',
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: 140, padding: '12px 6px' }}>Name</th>
                  <th style={{ width: 140, padding: '12px 6px' }}>Internal Name</th>                  
                  <th style={{ width: 140, padding: '12px 6px' }}> Actions</th>
                </tr>
              </thead>
              <tbody>
                {bProfiles.map((bProfile) => (
                  <tr key={bProfile.id}>
                    <td>
                      <Typography level="body-xs">{bProfile.label}</Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">{bProfile.internalName}</Typography>
                    </td>                                                            
                    <td>
                      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>                        
                        <RowMenu
                          menuItems={[
                            { label: 'Resource Permissions', onClick: () => handleResourcePermissionsClick(bProfile.id), color: 'default' },
                            { label: 'Update', onClick: () => handleOpenModal(bProfile.id.toString()), color: 'default' },
                            { label: 'Delete', onClick: () => console.log('Delete clicked'), color: 'danger' },
                          ]}
                        />
                      </Box>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        </Box>
      )}
      <BProfileForm
        open={isModalOpen}
        onClose={handleCloseModal}
        profileId={selectedProfile}
        showSnackBar={showSnackBar}
      />
    </React.Fragment>
  );
};

export default BProfileList;
