import React from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import { ReactFlow, Background, Edge, Node, ProOptions, Controls} from '@xyflow/react';
import useLayout from './hooks/useLayout';
import nodeTypes from './NodeTypes';
import edgeTypes from './EdgeTypes';
import { usePane } from '../context/PaneContext';


import '@xyflow/react/dist/style.css';

const proOptions: ProOptions = { account: 'paid-pro', hideAttribution: true };

const defaultNodes: Node[] = [
  {
    id: '1',
    data: { label: 'Add Trigger' },
    position: { x: 0, y: 150 },
    type: 'placeholder',
  },
];

const defaultEdges: Edge[] = [];

const fitViewOptions = {
  padding: 0.95,
};

const MainEditor: React.FC = () => {
  const { workflow } = usePane();
  
  useLayout();



  return (
    <Box
      sx={{
        flexGrow: 1,
        p: 3,
        width: '100%'        
      }}
    >
      <Typography level="h4" component="h2" mb={2}>
        {workflow?.name}
      </Typography>
      <ReactFlow
        defaultNodes={defaultNodes}
        defaultEdges={defaultEdges}
        proOptions={proOptions}
        fitView
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        fitViewOptions={fitViewOptions}
        minZoom={0.2}
        nodesDraggable={false}
        nodesConnectable={false}
        zoomOnDoubleClick={false}
        deleteKeyCode={null}        
        style={{ width: '100%' }} // Ensure ReactFlow also takes up 100% of the width
      >
        <Controls />
        <Background />
      </ReactFlow>
    </Box>
  );
};

export default MainEditor;
