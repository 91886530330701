import React, { useEffect, useState } from 'react';
import { Box, Modal, Typography, Table, Sheet, Checkbox, CircularProgress, Button } from '@mui/joy';
import { fetchData } from '../../../../middleware/api';
import { IBField } from '../../../../interfaces/BObject';
import { loadBField, saveBFieldPermission } from '../../../../services/b-object/BFieldService';

interface Profile {
  id: number;
  name: string;
  label: string;
}

interface PermissionsModalProps {
  open: boolean;
  onClose: () => void;
  fieldInternalName: string;
  objectName: string;
  showSnackBar: (message: string) => void;
}

const BFieldPermission: React.FC<PermissionsModalProps> = ({ open, onClose, fieldInternalName, objectName, showSnackBar }) => {
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [permissions, setPermissions] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    if (open) {
      const fetchProfilesAndBField = async () => {
        setLoading(true);
        try {
          const [profilesResponse, bField] = await Promise.all([
            fetchData('/_/admin/b-profiles/_/'),
            loadBField(objectName, fieldInternalName),
          ]);
          setProfiles(profilesResponse.content);
          initializePermissions(profilesResponse.content, bField);
        } catch (err) {
          if (err instanceof Error) {
            setError(err.message);
          } else {
            setError('An unknown error occurred');
          }
        } finally {
          setLoading(false);
        }
      };
      fetchProfilesAndBField();
    }
  }, [open, fieldInternalName, objectName]);

  const initializePermissions = (profiles: Profile[], bField: IBField) => {
    const initialPermissions: { [key: number]: string } = {};
    console.log(" existing permissions = " + JSON.stringify(bField))
    profiles.forEach((profile) => {
      if (bField && bField.permission && bField.permission[profile.id]) {
        initialPermissions[profile.id] = bField.permission[profile.id];
      } else {
        initialPermissions[profile.id] = 'HIDDEN';
      }
    });
    console.log(" updated permissions = " + JSON.stringify(initialPermissions))
    setPermissions(initialPermissions);
  };

  const handlePermissionChange = (profileId: number, permission: string) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [profileId]: permission,
    }));
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await saveBFieldPermission(objectName, fieldInternalName, permissions);
      showSnackBar('Permissions Updated Successfully!!!');
      onClose();
    } catch (error) {
      console.error('Error saving permissions:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={onClose}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Sheet sx={{ width: 600, p: 3, borderRadius: 'md', boxShadow: 'lg' }}>
          <Typography level="h4" component="h2" mb={2}>
            Edit Permissions
          </Typography>
          {loading ? (
            <CircularProgress />
          ) : error ? (
            <Typography color="danger">{error}</Typography>
          ) : (
            <Table>
              <thead>
                <tr>
                  <th>Label</th>
                  <th>Read</th>
                  <th>Write</th>
                  <th>Hidden</th>
                </tr>
              </thead>
              <tbody>
                {profiles.map((profile) => (
                  <tr key={profile.id}>
                    <td>{profile.label}</td>
                    <td>
                      <Checkbox
                        checked={permissions[profile.id] === 'READ'}
                        onChange={() => handlePermissionChange(profile.id, 'READ')}
                      />
                    </td>
                    <td>
                      <Checkbox
                        checked={permissions[profile.id] === 'WRITE'}
                        onChange={() => handlePermissionChange(profile.id, 'WRITE')}
                      />
                    </td>
                    <td>
                      <Checkbox
                        checked={permissions[profile.id] === 'HIDDEN'}
                        onChange={() => handlePermissionChange(profile.id, 'HIDDEN')}
                        disabled={
                          permissions[profile.id] === 'READ' ||
                          permissions[profile.id] === 'WRITE'
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="plain" color="neutral" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="solid" sx={{ ml: 1 }} onClick={handleSave}>
              Save
            </Button>
          </Box>
        </Sheet>
      </Modal>
    </React.Fragment>
  );
};

export default BFieldPermission;
