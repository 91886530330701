import React from 'react';
import { Menu, MenuButton, MenuItem, Dropdown } from '@mui/joy';
import IconButton from '@mui/joy/IconButton';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import Divider from '@mui/joy/Divider';

interface MenuItemProps {
  label: string;
  onClick: () => void;
  color?: string; // Updated to match expected values
}

interface RowMenuProps {
  menuItems: MenuItemProps[];
}

const RowMenu: React.FC<RowMenuProps> = ({ menuItems }) => {
  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
      >
        <MoreHorizRoundedIcon />
      </MenuButton>
      <Menu size="sm" sx={{ minWidth: 140 }}>
        {menuItems.map((item, index) => (
          <React.Fragment key={index}>
            {item.color === 'danger' && <Divider />}
            <MenuItem onClick={item.onClick} color={item.color === 'danger' ? 'danger' : undefined}>
              {item.label}
            </MenuItem>
          </React.Fragment>
        ))}
      </Menu>
    </Dropdown>
  );
};

export default RowMenu;
