import { memo } from 'react';
import { Handle, Position, NodeProps, BuiltInNode } from '@xyflow/react';
import cx from 'classnames';
import React from 'react';
import styles from './NodeTypes.module.css';
import { usePane } from '../../context/PaneContext';
import { NodeTypeEnum } from '../../../../../../services/workflow/WorkflowService';


const TriggerNode = ({ id }: NodeProps<BuiltInNode>) => {    
  const { workflow, setSelectedNode, setIsPaneVisible } = usePane();

  const triggerVerbMap: Record<'ON_CREATE' | 'ON_EDIT' | 'ON_CREATE_OR_EDIT', string> = {
    ON_CREATE: 'created',
    ON_EDIT: 'edited',
    ON_CREATE_OR_EDIT: 'created or updated',
  };


  const triggerNodeClick = () => {
    setSelectedNode(NodeTypeEnum.Trigger)
    setIsPaneVisible(true)
  }

  console.log("workflow?.triggerType ==> " + workflow?.triggerType)

  const triggerVerb = triggerVerbMap[workflow?.triggerType as 'ON_CREATE' | 'ON_EDIT' | 'ON_CREATE_OR_EDIT'] || '';

  return (
    <div
      onClick={triggerNodeClick}
      className={cx(styles.node)}      
    >
      {/* Top right: Trigger label as a chip */}
      <div 
        className={styles.chip} 
        style={{
          position: 'absolute',
          top: '4px',
          right: '4px',
          fontSize: '8px', 
          padding: '2px 6px', 
          borderRadius: '12px', 
          backgroundColor: '#ff5722',  // Contrasting background color
          color: '#ffffff',  // Contrasting text color
          display: 'inline-block',
          marginBottom: '2px',
        }}
      >
        Trigger
      </div>

      {/* Below: When <bobject_label> is <ITriggerType> */}
      <div className={styles.triggerContent} style={{ fontSize: '8px', marginTop: '20px', textAlign: 'center' }}>
        <span>
          When{' '}
          <span style={{ fontSize: '10px', color: '#ff5722' }}>
            {workflow?.bobject.label}
          </span>{' '}
          are {triggerVerb}
        </span>
      </div>

      <Handle
        className={styles.handle}
        type="target"
        position={Position.Top}
        isConnectable={false}
      />
      <Handle
        className={styles.handle}
        type="source"
        position={Position.Bottom}
        isConnectable={false}
      />
    </div>
  );
};

export default memo(TriggerNode);
