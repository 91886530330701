import React, { useEffect, useState } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import { Select, Option, Checkbox, Stack, Modal, Card, CardActions, CircularProgress, Typography } from '@mui/joy';
import AddIcon from '@mui/icons-material/Add';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IBField, IBFieldChoice } from '../../../../interfaces/BObject';
import { loadBField } from '../../../../services/b-object/BFieldService';
import ChoiceItem from './ChoiceItem';
import BFieldTypeDropDown from './BFieldTypeDropDown';

interface BFieldFormProps {
  open: boolean;
  onClose: () => void;
  objectName: string;
  fieldInternalName: string | null;
  showSnackBar: (message: string) => void;  
  handleSave: (bField: IBField, choices: IBFieldChoice[]) => void;
}

const BFieldForm: React.FC<BFieldFormProps> = ({
  open,
  onClose,
  objectName,
  fieldInternalName,
  showSnackBar,
  handleSave
}) => {

  const initialBFieldState: IBField = {
    id: 0,
    label: '',
    internalName: '',
    required: false,
    fieldType: { name: 'TEXT' },
    helpText: '',
    unique: false,
    additionalInfo: {},
    choices: [],
  };

  const [bField, setBField] = useState<IBField>(initialBFieldState);
  const [choices, setChoices] = useState<IBFieldChoice[]>([
    { id: 0, value: '', displayOrder: 0 },
    { id: 0, value: '', displayOrder: 1 },
  ]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (fieldInternalName) {
      setLoading(true);
      loadBField(objectName, fieldInternalName).then((data) => {
        console.log("Field data loaded: ", data);
        setBField(data);
        if (data.fieldType.name === 'DROPDOWN' && data.choices) {
          setChoices(data.choices.sort((a, b) => (a.displayOrder || 0) - (b.displayOrder || 0)));
        }
        setLoading(false);
      });
    } else {
      resetForm();
    }
  }, [fieldInternalName, objectName]);

  const resetForm = () => {
    setBField(initialBFieldState);
    setChoices([
      { id: 0, value: '', displayOrder: 0 },
      { id: 0, value: '', displayOrder: 1 },
    ]);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
    setBField((prevBField) => ({
      ...prevBField,
      [name!]: value,
    }));
  };

  const handleFieldTypeChange = (e: any, newValue: any) => {
    setBField((prevBField) => ({
      ...prevBField,
      fieldType: { name: newValue as string },
    }));
  };

  const handleChoiceChange = (index: number, value: string) => {
    setChoices((prevChoices) => {
      const updatedChoices = [...prevChoices];
      updatedChoices[index].value = value;
      return updatedChoices;
    });
  };

  const moveChoice = (dragIndex: number, hoverIndex: number) => {
    const dragChoice = choices[dragIndex];
    const updatedChoices = [...choices];
    updatedChoices.splice(dragIndex, 1);
    updatedChoices.splice(hoverIndex, 0, dragChoice);
    setChoices(updatedChoices);
  };

  const handleAddChoice = () => {
    setChoices((prevChoices) => [
      ...prevChoices,
      { id: 0, value: '', displayOrder: prevChoices.length },
    ]);
  };

  const handleRemoveChoice = (index: number) => {
    if (choices.length > 2) {
      setChoices((prevChoices) => prevChoices.filter((_, i) => i !== index));
    }
  };

  const onSave = async () => {
    setLoading(true);
    try {
      handleSave(bField, choices);
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Modal
        open={open}
        onClose={onClose}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Card sx={{ width: { xs: '100%', md: '40%' }, p: 3, borderRadius: 'md', boxShadow: 'lg' }}>
          <Typography level="h4" component="h2" mb={2}>
            {fieldInternalName ? 'Edit Field' : 'Create Field'}
          </Typography>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Label</FormLabel>
              <Input
                size="sm"
                placeholder="Field Label"
                name="label"
                value={bField.label}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Field Type</FormLabel>
              <BFieldTypeDropDown
                value={bField.fieldType.name}
                onChange={handleFieldTypeChange}
                disabled={!!fieldInternalName}
              />
            </FormControl>
            {(bField.fieldType.name === 'NUMBER' || bField.fieldType.name === 'LONG_NUMBER' || bField.fieldType.name === 'DECIMAL') && (
              <Box sx={{ display: 'flex', gap: 2 }}>
                <FormControl sx={{ flex: 1, maxWidth: '50%' }}>
                  <FormLabel>Digits</FormLabel>
                  <Select
                    size="sm"
                    name="digitCount"
                    value={bField.additionalInfo?.digitCount?.toString() ?? ''}
                    onChange={(e, newValue) =>
                      setBField((prevBField) => ({
                        ...prevBField,
                        additionalInfo: { ...prevBField.additionalInfo, digitCount: parseInt(newValue as string) },
                      }))
                    }
                  >
                    {Array.from({ length: bField.fieldType.name === 'LONG_NUMBER' ? 18 : bField.fieldType.name === 'DECIMAL' ? 16 : 9 }, (_, i) => (
                      <Option key={i + 1} value={(i + 1).toString()}>
                        {i + 1}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
                {bField.fieldType.name === 'DECIMAL' && (
                  <FormControl sx={{ flex: 1, maxWidth: '50%' }}>
                    <FormLabel>Decimal Places</FormLabel>
                    <Select
                      size="sm"
                      name="decimalCount"
                      value={bField.additionalInfo?.decimalCount?.toString() ?? ''}
                      onChange={(e, newValue) =>
                        setBField((prevBField) => ({
                          ...prevBField,
                          additionalInfo: { ...prevBField.additionalInfo, decimalCount: parseInt(newValue as string) },
                        }))
                      }
                    >
                      {Array.from({ length: 10 }, (_, i) => (
                        <Option key={i} value={i.toString()}>
                          {i}
                        </Option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Box>
            )}
            {bField.fieldType.name === 'DROPDOWN' && (
              <Box>
                <FormLabel>Choices</FormLabel>
                {choices.map((choice, index) => (
                  <ChoiceItem
                    key={index}
                    index={index}
                    choice={choice}
                    moveChoice={moveChoice}
                    handleChoiceChange={handleChoiceChange}
                    handleRemoveChoice={handleRemoveChoice}
                    choicesLength={choices.length}
                  />
                ))}
                <Button
                  size="sm"
                  variant="outlined"
                  color="primary"
                  onClick={handleAddChoice}
                  startDecorator={<AddIcon />}
                >
                  Add Choice
                </Button>
              </Box>
            )}
            <FormControl>
              <FormLabel>Required</FormLabel>
              <Checkbox
                checked={bField.required}
                name="required"
                onChange={(e) => setBField((prevBField) => ({ ...prevBField, required: e.target.checked }))}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Unique</FormLabel>
              <Checkbox
                checked={bField.unique}
                name="unique"
                onChange={(e) => setBField((prevBField) => ({ ...prevBField, unique: e.target.checked }))}
              />
            </FormControl>
          </Stack>
          <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button size="sm" variant="outlined" color="neutral" onClick={onClose} disabled={loading}>
              Cancel
            </Button>
            <Button
              size="sm"
              variant="solid"
              onClick={onSave}
              disabled={loading}
              startDecorator={loading && <CircularProgress sx={{ width: 16, height: 16 }} />}
              sx={{ ml: 1 }}
            >
              {loading ? 'Saving...' : 'Save'}
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </DndProvider>
  );
};

export default BFieldForm;
