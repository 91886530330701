import React, { useEffect, useState } from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { Input } from '@mui/joy';
import { usePane } from '../../context/PaneContext';
import { loadBFields } from '../../../../../../services/b-object/BObjectService';
import { IBField } from '../../../../../../interfaces/BObject';
import { NodeTypeEnum } from '../../../../../../services/workflow/WorkflowService';
import { IFilter, IFilterCondition, IRule } from '../../../../../../services/workflow/WorkflowService';

const FilterProperties: React.FC = () => {
  const { workflow, setWorkflow, setIsPaneVisible, setSelectedNode } = usePane(); 
  const [fields, setFields] = useState<IBField[]>([]);
  const [selectedField, setSelectedField] = useState<IBField | null>(null);
  const [operator, setOperator] = useState<string>('equals');
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    const fetchFields = async () => {
      if (workflow?.bobject?.internalName) {
        const fields = await loadBFields(workflow.bobject.internalName);        
        setFields(fields);
      }
    };
    fetchFields();
  }, [workflow?.bobject?.internalName]);

  const handleSave = () => {
    if (selectedField && value) {
      // Create a rule object
      const rule: IRule = {
        field: selectedField.internalName,
        operator: operator,
        type: selectedField.fieldType.name,
        value: value,
      };      
  
      // Create a filter condition object
      const filterCondition: IFilterCondition = {
        condition: 'AND',
        rules: [rule],
      };
  
      // Create the IFilter object
      const filter: IFilter = {
        condition: filterCondition        
      };

      // Ensure required fields are defined
      if (workflow?.name) {
        // Update the workflow with the new filter and description
        setWorkflow({
          ...workflow,
          filter: filter
        });          
      } else {
        console.error("Workflow name is required.");
      }
      
    } else {
      console.error("Field and value are required.");
    } 
  
    setIsPaneVisible(false);
    setSelectedNode(NodeTypeEnum.NONE);        
  };

  // Log the updated workflow to verify the state change
  useEffect(() => {
    console.log("Updated workflow after setWorkflow ==> ", workflow);
  }, [workflow]);
  
  return (
    <Box sx={{ width: '300px', p: 3 }}>
      <Typography level="h4" component="h2" mb={2}>
        Filter Properties
      </Typography>
      <Typography level="body-sm" mb={2}>
        A Filter defines a rule that must be met for the workflow to proceed. You can choose a field, an operator, and a value to set the condition.
      </Typography>
      <FormControl sx={{ mb: 3 }}>
        <FormLabel>Select Field</FormLabel>
        <Select
          value={selectedField}
          onChange={(e, newValue) => setSelectedField(newValue as IBField)}
          size="sm"
        >
          {fields.map((field) => (
            <Option key={field.id} value={field}>
              {field.label}
            </Option>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ mb: 3 }}>
        <FormLabel>Select Operator</FormLabel>
        <Select
          value={operator}
          onChange={(e, newValue) => setOperator(newValue as string)}
          size="sm"
        >
          <Option value="equals">Equals</Option>
          <Option value="not equals">Not Equals</Option>
          <Option value="starts with">Starts With</Option>
          <Option value="ends with">Ends With</Option>
        </Select>
      </FormControl>
      <FormControl sx={{ mb: 3 }}>
        <FormLabel>Value</FormLabel>       
        <Input
          size="sm"
          placeholder="Enter value"
          value={value}
          onChange={(e) => setValue(e.target.value as string)}
        />
      </FormControl>
      <Button variant="solid" color="primary" onClick={handleSave}>
        Done
      </Button>
    </Box>
  );
};

export default FilterProperties;
