import { IUser } from "../../interfaces/User";
import { fetchData, saveData, updateData } from '../../middleware/api';

const mapToUser = (payload: any): IUser => ({
  id: payload.id,
  firstName: payload.firstName,
  lastName: payload.lastName,
  email: payload.email,
  profile: {
    id: payload.profile.id,
    internalName: payload.profile.internalName,
    label: payload.profile.label,
    description: payload.profile.description,
  }
});

export const loadUsers = async (): Promise<IUser[]> => {
  const payload = await fetchData('/_/admin/users/_/');
  return payload.content.map((user: any) => mapToUser(user));
};

export const loadUser = async (userId: number): Promise<IUser> => {
  const payload = await fetchData(`/_/admin/users/_/${userId}/`);  
  return mapToUser(payload);
};

const mapToSaveUpdatePayload = (user: IUser) => ({
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    bProfileId: user.profile.id,
  });

export const saveUser = async (user: IUser): Promise<IUser> => {
  const payload = await saveData('/_/admin/users/_/', mapToSaveUpdatePayload(user));
  return mapToUser(payload);
};

export const updateUser = async (userId: number, user: IUser): Promise<IUser> => {
  const payload = await updateData(`/_/admin/users/_/${userId}/`, mapToSaveUpdatePayload(user));
  return mapToUser(payload);
};